import { Injectable } from '@angular/core';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ToastService } from 'src/app/core/services/toast.service';
import { Utils } from 'src/app/shared/utils';
import { Geolocation } from '@capacitor/geolocation';

export interface Location {
    lat: number;
    lng: number;
}

@Injectable()
export class GeolocationService {
    constructor(
        private openNativeSettings: OpenNativeSettings,
        private toastService: ToastService,
    ) {}

    async update(openSettingsIfDenied = false): Promise<Location> {
        try {
            const { location } = await Geolocation.checkPermissions();
            if (location === 'denied') {
                if (openSettingsIfDenied) {
                    void this.openNativeSettings.open('application_details');
                }

                return null;
            }

            const {
                coords: { latitude: lat, longitude: lng },
            } = await Geolocation.getCurrentPosition({
                enableHighAccuracy: false,
                timeout: 10000,
            });

            return { lat, lng };
        } catch (e) {
            console.log('geolocation error:', e);

            return null;
        }
    }

    debugMode(): Location {
        void this.toastService.present({
            message: 'Debug mode enabled, location is in Paris...',
        });

        return Utils.fixedLocation();
    }

    getDistance(point1: Location, point2: Location): string {
        if (!point1 || !point2) {
            return null;
        }

        const degreesToRadians = (degrees: number): number => {
            return (degrees * Math.PI) / 180;
        };

        const earthRadiusKm = 6371;

        const dLat = degreesToRadians(point1.lat - point2.lat);
        const dLon = degreesToRadians(point1.lng - point2.lng);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) *
                Math.sin(dLon / 2) *
                Math.cos(degreesToRadians(point2.lat)) *
                Math.cos(degreesToRadians(point1.lat));

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const result = Math.round((earthRadiusKm * c + Number.EPSILON) * 100) / 100;

        if (result < 1) {
            return `${result * 1000} m`;
        } else if (result < 10) {
            return `${result} km`;
        }

        return `${Math.round(result)} km`;
    }
}
