import { UserService } from 'src/app/core/services/dog-vacances-api/user/user.service';
import { PictureService } from 'src/app/core/services/dog-vacances-api/picture/picture.service';
import { PetsService } from 'src/app/core/services/dog-vacances-api/pets/pets.service';
import { PetSitterService } from 'src/app/core/services/dog-vacances-api/pet-sitter/pet-sitter.service';
import { PetSittersService } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.service';
import { BookingsService } from 'src/app/core/services/dog-vacances-api/bookings/bookings.service';
import { SseService } from 'src/app/core/services/dog-vacances-api/sse/sse.service';
import { DeviceService } from 'src/app/core/services/dog-vacances-api/device/device.service';

export const API_SERVICES = [
    UserService,
    PetsService,
    PetSitterService,
    PictureService,
    PetSittersService,
    BookingsService,
    SseService,
    DeviceService,
];
