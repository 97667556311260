import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input()
    set sticky(value: unknown) {
        this.isSticky = convertToBoolProperty(value);
    }

    @Input()
    set noBackButton(value: unknown) {
        this.hasBackButton = !convertToBoolProperty(value);
    }

    @Input()
    set closeButton(value: unknown) {
        this.hasCloseButton = convertToBoolProperty(value);
        if (this.hasCloseButton) {
            this.hasBackButton = false;
        }
    }

    @Input()
    set modal(value: unknown) {
        this.isModal = convertToBoolProperty(value);
    }

    @Input()
    set noShadow(value: unknown) {
        this.hasNoShadow = convertToBoolProperty(value);
    }

    @Input() mode: 'back' | 'close';
    @Input() backUrl: string | unknown[];
    @Input() color: 'lightPurple' | 'red' = 'lightPurple';

    @Output() onClose = new EventEmitter<void>();

    isSticky = false;
    hasBackButton = true;
    hasCloseButton = false;
    isModal = false;
    hasNoShadow = false;

    constructor(private navCtrl: NavController) {}

    goBack(): void {
        if (this.backUrl) {
            void this.navCtrl.navigateBack(this.backUrl, { replaceUrl: true });
        } else {
            // void this.navCtrl.back();
            void this.navCtrl.pop();
        }
    }
}
