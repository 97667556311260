import { createReducer, on } from '@ngrx/store';
import { SettingsActions } from 'src/stores/settings/settings.actions';
import { SettingsState } from 'src/stores/settings/settings.state';

export const initialState: SettingsState = {
    showNotificationsInstructions: true,
};

export const settingsReducer = createReducer(
    initialState,
    on(
        SettingsActions.setShowNotificationsInstructions,
        (state, { showNotificationsInstructions }) => ({ ...state, showNotificationsInstructions }),
    ),
);
