import { createAction, props } from '@ngrx/store';
import { Location } from 'src/app/core/services/geolocation.service';

const domain = '[LOCATION]';

const debugMode = createAction(`${domain} Debug mode`);
const update = createAction(`${domain} Update`, props<{ openSettingsIfDenied?: boolean }>());
const updateSuccess = createAction(
    `${domain} Update success`,
    props<{ coords: Location; status: 'ENABLED' | 'DISABLED' | 'DEBUG' }>(),
);

export const LocationActions = { debugMode, update, updateSuccess };
