/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormConfig } from 'src/app/shared/components/forms/form/form.config';

@Injectable()
export class CancelForm {
    getConfig(): FormConfig {
        return [
            {
                type: 'textarea',
                name: 'message',
                validators: Validators.required,
                config: {
                    placeholder: 'Votre message',
                    small: true,
                },
            },
        ];
    }
}
