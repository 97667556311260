import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CancelPetSittingDto,
    CreateChatMessageDto,
    CreateContactRequestDto,
    CreateReviewDto,
    SeeBookingDto,
} from 'src/app/core/services/dog-vacances-api/bookings/bookings-requests.dtos';
import {
    BookingResponseDto,
    ChatMessageResponseDto,
} from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { ContactRequestFormDto } from 'src/app/features/pet-sitting/forms/contact-request/contact-request-form.dto';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { environment } from 'src/environments/environment';

@Injectable()
export class BookingsService {
    private baseUrl = environment.api.baseUrl + '/bookings';

    constructor(private http: HttpClient) {}

    createContactRequest(
        petSitterId: string,
        formDto: ContactRequestFormDto,
    ): Observable<BookingResponseDto> {
        const { pet, ...other } = formDto;
        const dto: CreateContactRequestDto = { ...other, petId: pet.value };

        return this.http.post<BookingResponseDto>(
            `${this.baseUrl}/contact-request/${petSitterId}`,
            dto,
        );
    }

    getBookings(type: ClientType): Observable<BookingResponseDto[]> {
        const endpoint =
            type === 'client' ? `${this.baseUrl}/client` : `${this.baseUrl}/pet-sitter`;

        return this.http.get<BookingResponseDto[]>(endpoint);
    }

    getClientBookings(): Observable<BookingResponseDto[]> {
        return this.http.get<BookingResponseDto[]>(`${this.baseUrl}/client`);
    }

    getPetSitterBookings(): Observable<BookingResponseDto[]> {
        return this.http.get<BookingResponseDto[]>(`${this.baseUrl}/pet-sitter`);
    }

    approveContactRequest(bookingId: string): Observable<BookingResponseDto> {
        return this.http.put<BookingResponseDto>(
            `${this.baseUrl}/approve-contact-request/${bookingId}`,
            null,
        );
    }

    approvePetSitting(type: ClientType, bookingId: string): Observable<BookingResponseDto> {
        const endpoint = `${this.baseUrl}/approve-pet-sitting/${type}/${bookingId}`;

        return this.http.put<BookingResponseDto>(endpoint, null);
    }

    approvePetSittingClientSide(bookingId: string): Observable<BookingResponseDto> {
        return this.http.put<BookingResponseDto>(
            `${this.baseUrl}/approve-pet-sitting/client/${bookingId}`,
            null,
        );
    }

    approvePetSittingPetSitterSide(bookingId: string): Observable<BookingResponseDto> {
        return this.http.put<BookingResponseDto>(
            `${this.baseUrl}/approve-pet-sitting/pet-sitter/${bookingId}`,
            null,
        );
    }

    cancelPetSitting(bookingId: string, dto: CancelPetSittingDto): Observable<BookingResponseDto> {
        return this.http.put<BookingResponseDto>(
            `${this.baseUrl}/cancel-pet-sitting/${bookingId}`,
            dto,
        );
    }

    createReview(bookingId: string, dto: CreateReviewDto): Observable<BookingResponseDto> {
        return this.http.post<BookingResponseDto>(
            `${this.baseUrl}/create-review/${bookingId}`,
            dto,
        );
    }

    createChatMessage(
        bookingId: string,
        dto: CreateChatMessageDto,
    ): Observable<ChatMessageResponseDto> {
        return this.http.post<ChatMessageResponseDto>(
            `${this.baseUrl}/chat-message/${bookingId}`,
            dto,
        );
    }

    seeBookings(dto: SeeBookingDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/see`, dto);
    }

    seeMessages(bookingId: string): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/see-messages/${bookingId}`, null);
    }

    getBooking(bookingId: string): Observable<BookingResponseDto> {
        return this.http.get<BookingResponseDto>(`${this.baseUrl}/booking/${bookingId}`);
    }
}
