import {
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { CalendarModalComponent } from 'src/app/shared/components/forms/calendar/calendar-modal/calendar-modal.component';
import { DateRangeDto } from 'src/app/shared/dtos/date-range.dto';
import dayjs from 'dayjs';

@Component({
    selector: 'app-forms-calendar',
    templateUrl: './calendar.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
    ],
})
export class CalendarComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() formControlName: string;
    @Input() rangeMode: boolean;
    @Input() availabilities: string[];
    @Input() disableNonAvailableDates = false;

    control: FormControl;

    selected: string | DateRangeDto;

    onDestroy$ = new Subject<void>();

    propagateChange: (_: string | DateRangeDto) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
        console.log('availabilities', this.availabilities);
    }

    registerOnChange(fn: (_: string | DateRangeDto) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string | DateRangeDto): void {
        this.selected = value;
        if (value) {
            // this.control?.setValue(value, { emitEvent: false });
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    async openModal(): Promise<void> {
        const componentProps: { [key: string]: unknown } = {
            rangeMode: this.rangeMode,
            title: this.rangeMode ? 'Dates' : 'Date',
            showCurrent: true,
            disableNonAvailableDates: this.disableNonAvailableDates,
            availabilities: this.availabilities,
        };
        if (this.selected) {
            if (this.rangeMode) {
                const { start, end } = this.selected as DateRangeDto;
                componentProps.startDate = dayjs(start);
                componentProps.endDate = dayjs(end);
            } else {
                componentProps.startDate = dayjs(this.selected as string);
            }
        }
        const instance = await this.modalService.openIonModal({
            component: CalendarModalComponent,
            componentProps,
        });
        const { data } = await instance.onWillDismiss<string | DateRangeDto>();
        if (data) {
            this.selected = data;
            this.propagateChange(data);
        }
        this.control.markAsTouched();
    }

    get formattedSelected(): string {
        if (this.rangeMode) {
            const { start: startDate, end: endDate } = this.selected as DateRangeDto;
            const start = dayjs(startDate);
            const end = dayjs(endDate);
            let result = start.format('D');
            if (!start.isSame(end, 'month')) {
                result += ' ' + start.format('MMMM');
            }
            if (!start.isSame(end, 'year')) {
                result += ' ' + start.format('YYYY');
            }
            result += ' - ' + end.format('D MMMM YYYY ');

            return result;
        } else {
            const date = dayjs(this.selected as string);

            return date.format('D MMMM YYYY');
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
