import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { Location } from 'src/app/core/services/geolocation.service';
import { AutocompleteDto } from 'src/app/core/services/google-place/google-place.dtos';

@Injectable()
export class GooglePlacesService {
    constructor(mapsApiLoader: MapsAPILoader) {
        void mapsApiLoader.load();
    }

    async getPlaceDetails(
        placeId: string,
    ): Promise<{ location: Location; city?: string; country?: string }> {
        return new Promise((resolve) => {
            const map = new google.maps.Map(document.createElement('map'));

            const service = new google.maps.places.PlacesService(map);
            service.getDetails(
                { placeId, fields: ['geometry.location', 'address_components'] },
                (result) => {
                    const locality = result.address_components.find(
                        (item) => item.types.indexOf('locality') !== -1,
                    );
                    const country = result.address_components.find(
                        (item) => item.types.indexOf('country') !== -1,
                    );
                    const { location } = result.geometry;
                    resolve({
                        location: { lat: location.lat(), lng: location.lng() },
                        city: locality?.long_name,
                        country: country?.long_name,
                    });
                },
            );
        });
    }

    /**
     * Get autocomplete list of address
     */
    autocomplete(keyword: string): Promise<AutocompleteDto[]> {
        const service = new google.maps.places.AutocompleteService();

        const request = {
            input: keyword,
            componentRestrictions: { country: 'FR' },
            types: ['address'],
            // location:
        };

        return new Promise((resolve) => {
            service.getPlacePredictions(request, (results, status) => {
                if (status !== 'OK') {
                    return resolve([]);
                }

                const addresses = results.map((result) => {
                    const address: AutocompleteDto = {
                        description: result.description,
                        placeId: result.place_id,
                    };

                    return address;
                });

                resolve(addresses);
            });
        });
    }
}
