import { Injectable } from '@angular/core';
import { PetSittingTypes } from 'src/app/shared/guards/pet-sitting-type.guard';
import { AddressField } from 'src/app/shared/components/forms/address/address.config';
import { CalendarField } from 'src/app/shared/components/forms/calendar/calendar.config';
import {
    ExpandedSelectField,
    ExpandedSelectOption,
} from 'src/app/shared/components/forms/expanded-select/expanded-select.config';
import { FormConfig } from 'src/app/shared/components/forms/form/form.config';
import { RangeField } from 'src/app/shared/components/forms/range/range.config';
import { PetType } from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';
import { HomeType } from 'src/app/core/services/dog-vacances-api/pet-sitter/pet-sitter.dtos';
import { ValueSliderField } from 'src/app/shared/components/forms/value-slider/value-slider.config';

@Injectable()
export class PetSittingForms {
    // private getAlongOpts: ExpandedSelectOption[] = [
    //     { value: true, name: 'Oui' },
    //     { value: false, name: 'Non' },
    //     { value: null, name: 'Je ne sais pas', large: true },
    // ];

    getSearchFormConfig(type: PetSittingTypes): FormConfig {
        return [
            // date(s)
            this._getCalendarField(type),
            // address
            this._getAddressField(),
            // animalType
            this._getAnimalTypeField(),
        ];
    }

    // getForm2Config(): FormConfig {
    //     return [
    //         // // weight
    //         // this._getWeightField(),
    //         // // age
    //         // this._getAgeField(),
    //         // // getAlongWithDogs
    //         // this._getGetAlongWithDogsField(),
    //         // // getAlongWithCats
    //         // this._getGetAlongWithCatsField(),
    //     ];
    // }

    getFilterFormConfig(
        type: PetSittingTypes,
        priceRange: { minPrice: number; maxPrice: number },
    ): FormConfig {
        return [
            // sort
            this._sortByField(),
            // animalType
            this._getAnimalTypeField(),
            // weight
            // this._getWeightField(),
            // age
            // this._getAgeField(),
            // address
            this._getAddressField(),
            // searchRadius
            this._getSearchRadiusField(),
            // date(s)
            this._getCalendarField(type),
            // priceRange
            this._getPriceRangeField(priceRange.minPrice, priceRange.maxPrice),
            // homeType
            this._getHomeTypeField(),
            // gardenType
            this._getGardenTypeField(),
            // smokingAccomodation
            this._getSmokingAccomodationField(),
            // getAlongWithDogs
            // this._getGetAlongWithDogsField(),
            // getAlongWithCats
            // this._getGetAlongWithCatsField(),
        ];
    }

    private _getCalendarField(type: PetSittingTypes): CalendarField {
        const name = type === PetSittingTypes.ACCOMODATION ? 'dates' : 'date';
        const rangeMode = type === PetSittingTypes.ACCOMODATION;
        const placeholder =
            type === PetSittingTypes.ACCOMODATION
                ? 'Sélectionner des dates'
                : 'Sélectionner une date';

        let label: string;
        switch (type) {
            case PetSittingTypes.ACCOMODATION:
                label = `Dates de l'hébergement`;
                break;
            case PetSittingTypes.HOME_CARE:
                label = `Date de la garde à domicile`;
                break;
            case PetSittingTypes.NURSERY:
                label = `Dates de la garderie`;
                break;
            case PetSittingTypes.WALK:
                label = `Date de la promenade`;
                break;
        }

        return {
            type: 'calendar',
            name,
            label,
            config: { placeholder, rangeMode },
        };
    }

    private _getAddressField(): AddressField {
        return {
            type: 'address',
            name: 'location',
            label: 'Lieu',
            config: {
                placeholder: 'Sélectionner un lieu',
            },
        };
    }

    private _getSearchRadiusField(): ValueSliderField {
        return {
            type: 'value-slider',
            name: 'searchRadius',
            label: 'Rayon de recherche',
            config: {
                minValue: 1,
                maxValue: 50,
                suffix: 'km',
            },
        };
    }

    private _getAnimalTypeField(): ExpandedSelectField<PetType> {
        return {
            type: 'expanded-select',
            name: 'petType',
            label: `Je cherche un Pet-Sitter pour mon`,
            config: {
                options: [
                    { value: 'DOG', name: 'CHIEN', icon: 'forms/dog-icon' },
                    { value: 'CAT', name: 'CHAT', icon: 'forms/cat-icon' },
                ],
            },
        };
    }

    // private _getWeightField(): ExpandedSelectField<PetWeight> {
    //     return {
    //         type: 'expanded-select',
    //         name: 'weight',
    //         label: 'Quel est le poids de votre animal (kg)&nbsp;?',
    //         config: {
    //             options: [
    //                 { value: 'LESS_THAN_7', name: '0-7' },
    //                 { value: 'BETWEEN_7_18', name: '7-18' },
    //                 { value: 'BETWEEN_18_45', name: '18-45' },
    //                 { value: 'MORE_THAN_45', name: '45+' },
    //             ],
    //         },
    //     };
    // }

    private _sortByField(): ExpandedSelectField<'DISTANCE' | 'RANKING'> {
        return {
            type: 'expanded-select',
            name: 'sortBy',
            label: 'Trier les Pet-Sitters par',
            config: {
                options: [
                    {
                        value: 'DISTANCE',
                        name: 'DISTANCE',
                        large: true,
                        icon: 'forms/target',
                    },
                    { value: 'RANKING', name: 'AVIS', large: true, icon: 'star-outline' },
                ],
            },
        };
    }

    private _getAgeField(): ExpandedSelectField {
        const ageOpts: ExpandedSelectOption[] = [
            { value: false, name: 'Moins de 1 an', large: true },
            { value: true, name: 'Adulte', large: true },
        ];

        return {
            type: 'expanded-select',
            name: 'isAdult',
            label: `Quel est l'âge de votre animal&nbsp;?`,
            config: {
                options: ageOpts,
            },
        };
    }

    // private _getGetAlongWithDogsField(): ExpandedSelectField {
    //     return {
    //         type: 'expanded-select',
    //         name: 'getAlongWithDogs',
    //         label: `S'entend-il avec les chiens&nbsp;?`,
    //         config: {
    //             options: this.getAlongOpts,
    //         },
    //     };
    // }

    // private _getGetAlongWithCatsField(): ExpandedSelectField {
    //     return {
    //         type: 'expanded-select',
    //         name: 'getAlongWithCats',
    //         label: `S'entend-il avec les chats&nbsp;?`,
    //         config: {
    //             options: this.getAlongOpts,
    //         },
    //     };
    // }

    private _getPriceRangeField(minPrice: number, maxPrice: number): RangeField {
        return {
            type: 'range',
            name: 'priceRange',
            label: 'Tranche de prix',
            config: {
                minValue: minPrice,
                maxValue: maxPrice,
                currency: true,
            },
        };
    }

    private _getHomeTypeField(): ExpandedSelectField {
        const homeTypeOpts: ExpandedSelectOption<HomeType>[] = [
            { value: 'HOUSE', name: 'Maison', icon: 'forms/house-icon' },
            {
                value: 'APARTMENT',
                name: 'Appartement',
                icon: 'forms/apartment-icon',
            },
            { value: null, name: `N'importe`, icon: 'forms/whatever-icon' },
        ];

        return {
            type: 'expanded-select',
            name: 'homeType',
            label: 'Type de logement',
            config: {
                options: homeTypeOpts,
                big: true,
            },
        };
    }

    private _getGardenTypeField(): ExpandedSelectField {
        const gardenTypeOpts: ExpandedSelectOption<boolean>[] = [
            { value: true, name: 'Oui', icon: 'forms/fenced-garden-icon' },
            {
                value: false,
                name: 'Non',
                icon: 'forms/non-fenced-garden-icon',
            },
            { value: null, name: `N'importe`, icon: 'forms/whatever-icon' },
        ];

        return {
            type: 'expanded-select',
            name: 'hasFencedGarden',
            label: 'Possède un jardin clôturé',
            config: {
                options: gardenTypeOpts,
                big: true,
            },
        };
    }

    private _getSmokingAccomodationField(): ExpandedSelectField {
        const smokingAccomodationOpts: ExpandedSelectOption<boolean>[] = [
            {
                value: true,
                name: 'Oui',
                icon: 'forms/smoking-accomodation-icon',
            },
            {
                value: false,
                name: 'Non',
                icon: 'forms/non-smoking-accomodation-icon',
            },
            { value: null, name: `N'importe`, icon: 'forms/whatever-icon' },
        ];

        return {
            type: 'expanded-select',
            name: 'smokingAccommodation',
            label: 'Logement fumeur',
            config: {
                options: smokingAccomodationOpts,
                big: true,
            },
        };
    }
}
