import {
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { PictureService } from 'src/app/core/services/dog-vacances-api/picture/picture.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { PictureDto } from 'src/app/shared/dtos/picture.dto';
import { Utils } from 'src/app/shared/utils';
import { Camera, CameraResultType } from '@capacitor/camera';

export interface PictureBlock {
    picture: PictureDto;
    isLoading: boolean;
}

@Component({
    selector: 'app-forms-pictures',
    templateUrl: './pictures.component.html',
    styleUrls: ['./pictures.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PicturesComponent),
            multi: true,
        },
    ],
})
export class PicturesComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() text: string;
    @Input() icon = 'forms/picture-icon';
    @Input() folder: string;
    @Input() allowEditing = false;

    isLoading = false;
    pictures: PictureBlock[];

    control: FormControl;

    onDestroy$ = new Subject<void>();

    propagateChange: (_: PictureDto[]) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private pictureService: PictureService,
        private modalService: ModalService,
    ) {
        this.pictures = [
            { picture: null, isLoading: false },
            { picture: null, isLoading: false },
            { picture: null, isLoading: false },
            { picture: null, isLoading: false },
            { picture: null, isLoading: false },
            { picture: null, isLoading: false },
        ];
    }

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: PictureDto[]) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: PictureDto[]): void {
        if (value) {
            let i = 0;
            for (const picture of value) {
                if (this.pictures[i]) {
                    this.pictures[i].picture = picture;
                }
                ++i;
            }
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    async uploadPicture(): Promise<void> {
        const pictureBlock = this.pictures.find((item) => !item.picture && !item.isLoading);
        if (!pictureBlock) {
            return;
        }

        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: this.allowEditing,
            resultType: CameraResultType.DataUrl,
            presentationStyle: 'popover',
            width: 1000,
            preserveAspectRatio: true,
        });

        const file = Utils.dataURItoBlob(image.dataUrl);

        pictureBlock.isLoading = true;
        this.pictureService.upload({ folder: this.folder }, file).subscribe((picture) => {
            pictureBlock.isLoading = false;
            pictureBlock.picture = picture;
            this.propagateChange(
                this.pictures.filter((item) => item.picture).map((item) => item.picture),
            );
        });
    }

    async removePicture(picture: PictureBlock): Promise<void> {
        const event = await this.modalService.confirm({
            title: 'Confirmation',
            text: `Confirmez-vous la suppression de l'image&nbsp;?`,
            icon: 'assets/images/dog-tears.svg',
            color: 'red',
        });
        if (event === 'CONFIRMED') {
            this.pictures = this.pictures.filter(
                (item) => !item.picture || item.picture.id !== picture.picture.id,
            );
            this.pictures.push({ picture: null, isLoading: false });
            this.propagateChange(
                this.pictures.filter((item) => item.picture).map((item) => item.picture),
            );
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
