import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getAllDataFromLocalForage, default as localForage } from 'ngrx-store-persist';

/** DayJS plugins and locale registration */
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Capacitor } from '@capacitor/core';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.locale('fr');

if (environment.production) {
    enableProdMode();
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
localForage.ready().then(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    getAllDataFromLocalForage({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        driver: Capacitor.getPlatform() === 'web' ? localForage.WEBSQL : localForage.LOCALSTORAGE,
        keys: ['location', 'auth', 'petSitting', 'settings'],
    }).then(() => {
        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch((err) => console.log(err));
    });
});
