import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadedFileDto } from 'src/app/core/services/dog-vacances-api/picture/picture.dtos';
import { PictureDto } from 'src/app/shared/dtos/picture.dto';
import { environment } from 'src/environments/environment';

@Injectable()
export class PictureService {
    private baseUrl = environment.api.baseUrl + '/picture';

    constructor(private http: HttpClient) {}

    upload(dto: UploadedFileDto, file: Blob): Observable<PictureDto> {
        const data = new FormData();
        data.append('file', file);
        data.append('folder', dto.folder);

        return this.http.post<PictureDto>(`${this.baseUrl}/upload`, data);
    }
}
