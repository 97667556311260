import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';

@Injectable()
export class HapticService {
    constructor(private platform: Platform) {}

    lightImpact(): void {
        if (this.platform.is('capacitor')) {
            void Haptics.impact({ style: ImpactStyle.Light });
        }
    }

    mediumImpact(): void {
        if (this.platform.is('capacitor')) {
            void Haptics.impact({ style: ImpactStyle.Medium });
        }
    }

    heavyImpact(): void {
        if (this.platform.is('capacitor')) {
            void Haptics.impact({ style: ImpactStyle.Heavy });
        }
    }

    warningNotification(): void {
        if (this.platform.is('capacitor')) {
            void Haptics.notification({ type: NotificationType.Warning });
        }
    }

    errorNotification(): void {
        if (this.platform.is('capacitor')) {
            void Haptics.notification({ type: NotificationType.Error });
        }
    }

    successNotification(): void {
        if (this.platform.is('capacitor')) {
            void Haptics.notification({ type: NotificationType.Success });
        }
    }
}
