import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { Observable } from 'rxjs';
import {
    EditUserDto,
    EmailExistsDto,
    SignupDto,
    SignupResponseDto,
    UserResponseDto,
} from 'src/app/core/services/dog-vacances-api/user/user.dtos';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {
    private baseUrl = environment.api.baseUrl + '/user';

    constructor(private http: HttpClient) {}

    emailExists(dto: EmailExistsDto): Observable<boolean> {
        const params = new HttpParams({
            fromObject: classToPlain(dto),
            encoder: Utils.httpParameterEncoder,
        });

        return this.http.get<boolean>(`${this.baseUrl}/email-exists`, { params });
    }

    signup(dto: SignupDto): Observable<SignupResponseDto> {
        return this.http.post<SignupResponseDto>(`${this.baseUrl}/signup`, dto);
    }

    getInfo(): Observable<UserResponseDto> {
        return this.http.get<UserResponseDto>(`${this.baseUrl}/info`);
    }

    editUser(dto: EditUserDto): Observable<UserResponseDto> {
        return this.http.put<UserResponseDto>(`${this.baseUrl}/edit`, dto);
    }
}
