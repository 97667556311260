import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { authReducer } from 'src/stores/auth/auth.reducers';
import { AuthState } from 'src/stores/auth/auth.state';
import { locationReducer } from 'src/stores/location/location.reducers';
import { LocationState } from 'src/stores/location/location.state';
import { clientReducer } from 'src/stores/client/client.reducers';
import { platformReducer } from 'src/stores/platform/platform.reducers';
import { PlatformState } from 'src/stores/platform/platform.state';
import { ClientState } from 'src/stores/client/client.state';
import { ClientsEffects } from 'src/stores/client/client.effects';
import { petSitterReducer } from 'src/stores/pet-sitter/pet-sitter.reducers';
import { PetSitterState } from 'src/stores/pet-sitter/pet-sitter.state';
import { PetSitterEffects } from 'src/stores/pet-sitter/pet-sitter.effects';
import { AuthEffects } from 'src/stores/auth/auth.effects';
import { petSittingReducer } from 'src/stores/pet-sitting/pet-sitting.reducers';
import { PetSittingState } from 'src/stores/pet-sitting/pet-sitting.state';
import { PetSittingEffects } from 'src/stores/pet-sitting/pet-sitting.effects';
import { BookingsEffects } from 'src/stores/bookings/bookings.effects';
import { bookingsReducer } from 'src/stores/bookings/bookings.reducers';
import { BookingsState } from 'src/stores/bookings/bookings.state';
import { SseEffects } from 'src/stores/sse/sse.effects';
import { settingsReducer } from 'src/stores/settings/settings.reducers';
import { SettingsState } from 'src/stores/settings/settings.state';
import { LocationEffects } from 'src/stores/location/location.effects';

export const rootReducer = {
    router: routerReducer,
    platform: platformReducer,
    location: locationReducer,
    auth: authReducer,
    client: clientReducer,
    petSitter: petSitterReducer,
    petSitting: petSittingReducer,
    bookings: bookingsReducer,
    settings: settingsReducer,
};

export const rootEffects = [
    AuthEffects,
    ClientsEffects,
    PetSitterEffects,
    PetSittingEffects,
    BookingsEffects,
    SseEffects,
    LocationEffects,
];

export interface AppState {
    router: RouterReducerState;
    platform: PlatformState;
    location: LocationState;
    auth: AuthState;
    client: ClientState;
    petSitter: PetSitterState;
    petSitting: PetSittingState;
    bookings: BookingsState;
    settings: SettingsState;
}
