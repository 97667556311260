import { createReducer, on } from '@ngrx/store';
import { AuthActions } from 'src/stores/auth/auth.actions';
import { AuthState } from 'src/stores/auth/auth.state';

export const initialState: AuthState = {
    isLogged: false,
    user: null,
    redirectUrl: null,
    petSitterName: null,
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.login, (state, { url, petSitterName }) => ({
        ...state,
        ...(url ? { redirectUrl: url } : {}),
        ...(petSitterName ? { petSitterName } : {}),
        ...(url === null ? { redirectUrl: null } : {}),
    })),
    on(AuthActions.signup, (state, { url }) => ({
        ...state,
        ...(url ? { redirectUrl: url } : {}),
        ...(url === null ? { redirectUrl: null } : {}),
    })),
    on(AuthActions.logoutSuccess, () => ({ ...initialState })),
    on(AuthActions.editUserSuccess, (state, { user }) => ({ ...state, user })),
    on(AuthActions.loadUserSuccess, (state, { user }) => ({ ...state, isLogged: true, user })),
);
