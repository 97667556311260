import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/core/services/modal.service';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { SseActions } from 'src/stores/sse/sse.actions';

@Injectable()
export class SseEffects {
    newMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.newMessage),
            map(({ payload }) => BookingsActions.newMessage({ payload })),
        ),
    );

    newContactRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.newContactRequest),
            map(({ payload: { bookingId } }) =>
                BookingsActions.fetchAndUpdateBookings({ clientType: 'pet-sitter', bookingId }),
            ),
        ),
    );

    contactRequestApproved$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.contactRequestApproved),
            map(({ payload: { bookingId } }) =>
                BookingsActions.fetchAndUpdateBookings({ clientType: 'client', bookingId }),
            ),
        ),
    );

    petSittingApproved$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.petSittingApproved),
            map(({ payload: { bookingId, clientType } }) =>
                BookingsActions.fetchAndUpdateBookings({ clientType, bookingId }),
            ),
        ),
    );

    petSittingCanceled$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.petSittingCanceled),
            map(({ payload: { bookingId, clientType } }) =>
                BookingsActions.fetchAndUpdateBookings({ clientType, bookingId }),
            ),
        ),
    );

    petSittingExpired$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.petSittingExpired),
            map(({ payload: { bookingId } }) => BookingsActions.removeBooking({ bookingId })),
        ),
    );

    petSittingFinished$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.petSittingFinished),
            map(({ payload: { bookingId, clientType } }) =>
                BookingsActions.fetchAndUpdateBookings({ clientType, bookingId }),
            ),
        ),
    );

    petSittingRated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SseActions.petSittingRated),
            map(({ payload: { bookingId } }) =>
                BookingsActions.fetchAndUpdateBookings({ clientType: 'pet-sitter', bookingId }),
            ),
        ),
    );

    notification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SseActions.notification),
                tap(({ payload: { title, text } }) => {
                    void this.modalService.alert({ title, text, confirmTxt: 'OK' });
                }),
            ),
        { dispatch: false },
    );

    constructor(private actions$: Actions, private modalService: ModalService) {}
}
