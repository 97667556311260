import { PipeTransform, Pipe } from '@angular/core';
import { PetType } from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';

@Pipe({ name: 'petType' })
export class PetTypePipe implements PipeTransform {
    transform(value: PetType): string {
        if (value === 'CAT') {
            return 'Chat';
        } else {
            return 'Chien';
        }
    }
}
