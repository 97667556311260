import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

export enum PetSittingTypes {
    ACCOMODATION = 'ACCOMMODATION',
    HOME_CARE = 'HOME_CARE',
    NURSERY = 'NURSERY',
    WALK = 'WALK',
}

@Injectable()
export class PetSittingTypeGuard implements CanActivate {
    constructor(private navCtrl: NavController) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!Object.values(PetSittingTypes).includes(route.params.type)) {
            void this.navCtrl.navigateForward(['/home']);

            return false;
        }

        return true;
    }
}
