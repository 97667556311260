import { Component, Input } from '@angular/core';
import { HapticService } from 'src/app/core/services/haptic.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SelectOption } from 'src/app/shared/components/forms/select/select.config';

@Component({
    templateUrl: './select-modal.component.html',
})
export class SelectModalComponent {
    @Input() title: string;
    @Input() options: SelectOption[];
    @Input() color: 'lightPurple' | 'red';

    constructor(private modalService: ModalService, private haptic: HapticService) {}

    select(option: SelectOption): void {
        this.haptic.mediumImpact();
        this.modalService.dismissIonModal(option);
    }

    close(): void {
        this.modalService.dismissIonModal();
    }
}
