import { createReducer, on } from '@ngrx/store';
import { PetSittingActions } from 'src/stores/pet-sitting/pet-sitting.actions';
import { PetSittingState } from 'src/stores/pet-sitting/pet-sitting.state';

export const initialState: PetSittingState = {
    currentPage: 1,
    totalPages: 1,
    searchParams: null,
    results: null,
    contactRequest: {
        petSitterId: null,
        petSitterPetPreference: null,
        form: null,
    },
    minPrice: null,
    maxPrice: null,
};

export const petSittingReducer = createReducer(
    initialState,
    on(PetSittingActions.updateSearchParams, (state, { dto }) => ({ ...state, searchParams: dto })),
    on(PetSittingActions.search, ({ searchParams }) => ({ ...initialState, searchParams })),
    on(PetSittingActions.searchSuccess, (state, { results, totalPages, minPrice, maxPrice }) => ({
        ...state,
        results,
        totalPages,
        minPrice,
        maxPrice,
    })),
    on(PetSittingActions.loadMore, (state) => ({ ...state, currentPage: state.currentPage + 1 })),
    on(PetSittingActions.loadMoreSuccess, (state, { results, totalPages }) => ({
        ...state,
        results: [...state.results, ...results],
        totalPages,
    })),
    on(PetSittingActions.clear, () => ({ ...initialState })),

    /**
     * Contact request
     */
    on(
        PetSittingActions.contactRequest.setPetSitterId,
        ({ contactRequest, ...state }, { petSitterId }) => {
            contactRequest = { ...contactRequest, petSitterId };

            return { ...state, contactRequest };
        },
    ),
    on(
        PetSittingActions.contactRequest.setPetSitterPetPreference,
        ({ contactRequest, ...state }, { petSitterPetPreference }) => {
            contactRequest = { ...contactRequest, petSitterPetPreference };

            return { ...state, contactRequest };
        },
    ),
    on(PetSittingActions.contactRequest.setPet, ({ contactRequest, ...state }, { pet }) => {
        const { form, ...other } = contactRequest;

        return {
            ...state,
            contactRequest: {
                ...other,
                form: {
                    ...form,
                    pet: {
                        value: pet.id,
                        label: `${pet.name} (${pet.type === 'CAT' ? 'Chat' : 'Chien'} - ${
                            pet.breed
                        })`,
                    },
                },
            },
        };
    }),
    on(PetSittingActions.contactRequest.setForm, ({ contactRequest, ...state }, { form }) => {
        return { ...state, contactRequest: { ...contactRequest, form } };
    }),
    on(PetSittingActions.contactRequest.clear, (state) => {
        const { contactRequest } = initialState;
        const { contactRequest: current, ...other } = state;

        return { ...other, contactRequest };
    }),
);
