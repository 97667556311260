import { createAction, props } from '@ngrx/store';
import {
    CancelPetSittingDto,
    CreateChatMessageDto,
    CreateReviewDto,
} from 'src/app/core/services/dog-vacances-api/bookings/bookings-requests.dtos';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { NewMessagePayload } from 'src/app/core/services/dog-vacances-api/sse/sse.dtos';
import { BookingTab } from 'src/app/features/user/guards/booking-tab.guard';
import { ClientType } from 'src/app/shared/guards/client-type.guard';

const domain = '[BOOKINGS]';

/**
 * Bookings
 */

const loadBookings = createAction(`${domain} Load bookings`, props<{ clientType: ClientType }>());
const loadBookingsSuccess = createAction(
    `${domain} Load bookings success`,
    props<{ clientType: ClientType; bookings: BookingResponseDto[] }>(),
);
const updateBookings = createAction(
    `${domain} Update bookings`,
    props<{ clientType: ClientType; booking: BookingResponseDto }>(),
);
const removeBooking = createAction(`${domain} Remove booking`, props<{ bookingId: string }>());
const fetchAndUpdateBookings = createAction(
    `${domain} Fetch and update bookings`,
    props<{ clientType: ClientType; bookingId: string }>(),
);
const seeBookings = createAction(
    `${domain} See bookings`,
    props<{ clientType: ClientType; tab: BookingTab }>(),
);
const clear = createAction(`${domain} Clear bookings`);
const BOOKINGS_ACTIONS = {
    loadBookings,
    loadBookingsSuccess,
    updateBookings,
    removeBooking,
    fetchAndUpdateBookings,
    seeBookings,
    clear,
};

/**
 * Messages
 */

const seeMessages = createAction(
    `${domain} See messages`,
    props<{ clientType: ClientType; booking: BookingResponseDto }>(),
);
const newMessage = createAction(`${domain} New message`, props<{ payload: NewMessagePayload }>());
const MESSAGE_ACTIONS = { seeMessages, newMessage };

/**
 * Approve contact request
 */

const approveContactRequest = createAction(
    `${domain} Approve contact request`,
    props<{ booking: BookingResponseDto }>(),
);
const approveContactRequestSuccess = createAction(
    `${domain} Approve contact request success`,
    props<{ booking: BookingResponseDto }>(),
);
const approveContactRequestError = createAction(
    `${domain} Approve contact request error`,
    props<{ error: string }>(),
);
const APPROVE_CONTACT_REQUEST_ACTIONS = {
    approveContactRequest,
    approveContactRequestSuccess,
    approveContactRequestError,
};

/**
 * Approve Pet-Sitting
 */

const approvePetSitting = createAction(
    `${domain} Approve Pet-Sitting`,
    props<{ clientType: ClientType; booking: BookingResponseDto }>(),
);
const approvePetSittingSuccess = createAction(
    `${domain} Approve Pet-Sitting success`,
    props<{ clientType: ClientType; booking: BookingResponseDto }>(),
);
const approvePetSittingError = createAction(
    `${domain} Approve Pet-Sitting error`,
    props<{ clientType: ClientType; error: string }>(),
);
const APPROVE_PET_SITTING_ACTIONS = {
    approvePetSitting,
    approvePetSittingSuccess,
    approvePetSittingError,
};

/**
 * Cancel Pet-Sitting
 */

const cancelPetSitting = createAction(
    `${domain} Cancel Pet-Sitting`,
    props<{ clientType: ClientType; booking: BookingResponseDto; dto: CancelPetSittingDto }>(),
);
const cancelPetSittingSuccess = createAction(
    `${domain} Cancel Pet-Sitting success`,
    props<{ clientType: ClientType; booking: BookingResponseDto }>(),
);
const cancelPetSittingError = createAction(
    `${domain} Cancel Pet-Sitting error`,
    props<{ clientType: ClientType; error: string }>(),
);
const CANCEL_PET_SITTING_ACTIONS = {
    cancelPetSitting,
    cancelPetSittingSuccess,
    cancelPetSittingError,
};

/**
 * Create review
 */

const createReview = createAction(
    `${domain} Create review`,
    props<{ booking: BookingResponseDto; dto: CreateReviewDto }>(),
);
const createReviewSuccess = createAction(
    `${domain} Create review success`,
    props<{ booking: BookingResponseDto }>(),
);
const createReviewError = createAction(`${domain} Create review error`, props<{ error: string }>());
const CREATE_REVIEW_ACTIONS = {
    createReview,
    createReviewSuccess,
    createReviewError,
};

/**
 * Create chat message
 */

const createChatMessage = createAction(
    `${domain} Create chat message`,
    props<{ clientType: ClientType; booking: BookingResponseDto; dto: CreateChatMessageDto }>(),
);
const createChatMessageSuccess = createAction(
    `${domain} Create chat message success`,
    props<{ clientType: ClientType; booking: BookingResponseDto }>(),
);
const CREATE_CHAT_MESSAGE_ACTIONS = { createChatMessage, createChatMessageSuccess };

export const BookingsActions = {
    ...BOOKINGS_ACTIONS,
    ...MESSAGE_ACTIONS,
    ...APPROVE_CONTACT_REQUEST_ACTIONS,
    ...APPROVE_PET_SITTING_ACTIONS,
    ...CANCEL_PET_SITTING_ACTIONS,
    ...CREATE_REVIEW_ACTIONS,
    ...CREATE_CHAT_MESSAGE_ACTIONS,
};
