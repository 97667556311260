import { MenuController } from '@ionic/angular';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { CalendarMonthComponent } from 'src/app/shared/components/calendar-month/calendar-month.component';
import { CardComponent } from 'src/app/shared/components/card/card.component';
import { EmptyListComponent } from 'src/app/shared/components/empty-list/empty-list.component';
import { FORM_COMPONENTS } from 'src/app/shared/components/forms';
import { GalleryModalComponent } from 'src/app/shared/components/gallery-modal/gallery-modal.component';
import { HeaderButtonComponent } from 'src/app/shared/components/header-button/header-button.component';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { LinkCardComponent } from 'src/app/shared/components/link-card/link-card.component';
import { ListItemComponent } from 'src/app/shared/components/list-item/list-item.component';
import { ListComponent } from 'src/app/shared/components/list/list.component';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { SplashScreenComponent } from 'src/app/shared/components/splash-screen/splash-screen.component';
import { StarRatingComponent } from 'src/app/shared/components/star-rating/star-rating.component';
import { TitleComponent } from 'src/app/shared/components/title/title.component';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';

export const SHARED_COMPONENTS = [
    SplashScreenComponent,
    ButtonComponent,
    AlertModalComponent,
    TitleComponent,
    ListComponent,
    ListItemComponent,
    LinkCardComponent,
    HeaderComponent,
    ...FORM_COMPONENTS,
    CalendarMonthComponent,
    HeaderButtonComponent,
    CardComponent,
    StarRatingComponent,
    GalleryModalComponent,
    EmptyListComponent,
    LoadingComponent,
    MenuComponent,
];
