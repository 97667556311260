import { ChangeDetectorRef, Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent {
    splashScreenExists = true;
    splashScreenVisible = true;

    constructor(private cdr: ChangeDetectorRef, private platform: Platform) {}

    hideSplashScreen(): void {
        setTimeout(() => {
            this.splashScreenVisible = false;
            if (this.platform.is('capacitor') && this.platform.is('ios')) {
                void StatusBar.setStyle({ style: Style.Light });
            }
            this.cdr.detectChanges();

            setTimeout(() => {
                this.splashScreenExists = false;
                this.cdr.detectChanges();
            }, 200);
        }, 3500);
    }
}
