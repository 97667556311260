import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'times' })
export class TimesPipe implements PipeTransform {
    transform(value: number): number[] {
        const result: number[] = [];
        for (let i = 0; i < value; ++i) {
            result.push(i);
        }

        return result;
    }
}
