import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

export interface Month {
    offsetStart: number;
    offsetEnd: number;
    days: string[];
    name: string;
}

@Injectable()
export class CalendarService {
    getMonthsToDisplay(n: number): Month[] {
        const months: Month[] = [];
        const now = dayjs();
        for (let i = 0; i < n; ++i) {
            const startOfMonth = now.add(i, 'month').startOf('month');
            const offsetStart = (+startOfMonth.format('d') + 6) % 7;
            const daysOfMonth = +startOfMonth.endOf('month').format('D');
            const offsetEnd = 7 - ((daysOfMonth + offsetStart) % 7);

            const month = startOfMonth.format('MM');
            const year = startOfMonth.format('YYYY');

            const days = [];

            for (let j = 1; j <= daysOfMonth; ++j) {
                const day = dayjs(`${year}-${month}-${j}`);
                days.push(day.format('YYYY-MM-DD'));
            }

            months.push({
                offsetStart,
                offsetEnd,
                days,
                name: startOfMonth.format('MMMM YYYY'),
            });
        }

        return months;
    }
}
