import { HttpErrorResponse, HttpParameterCodec } from '@angular/common/http';
import { Location } from 'src/app/core/services/geolocation.service';
import urlencode from 'urlencode';

export class Utils {
    static httpParameterEncoder: HttpParameterCodec = {
        encodeKey: (key: string) => key,
        decodeKey: (key: string) => key,
        encodeValue: (value: string) => urlencode.encode(value),
        decodeValue: (value: string) => urlencode.decode(value),
    };

    static async wait(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    static fixedLocation(): Location {
        // 45 rue de Châteaudun, Ivry sur Seine, France
        return {
            lat: 48.815149,
            lng: 2.364781,
        };
    }

    static getHttpErrorCode(error: unknown): string {
        if (error instanceof HttpErrorResponse) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            return error.error.error;
        }

        return null;
    }

    static dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: mimeString });

        return blob;
    }
}
