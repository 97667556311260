import {
    Component,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-forms-checkbox-card',
    templateUrl: './checkbox-card.component.html',
    styleUrls: ['./checkbox-card.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxCardComponent),
            multi: true,
        },
    ],
})
export class CheckboxCardComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() text: string;
    @Input() icon?: string;
    @Input() iconColor: 'lightPurple' | 'red' | 'yellow' = 'red';
    @Input() color: 'lightPurple' | 'red' = 'lightPurple';

    @Input() checked = false;
    @Output() checkedChange = new EventEmitter<boolean>();

    control: FormControl;

    onDestroy$ = new Subject<void>();

    propagateChange: (_: boolean) => void;
    propagateTouch: () => void;

    constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: boolean) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: boolean): void {
        this.control?.setValue(value);
        if (value) {
            this.control?.markAsTouched();
        }
    }

    toggle(): void {
        this.checked = !this.checked;
        this.checkedChange.emit(this.checked);
        if (this.control) {
            this.propagateChange(this.checked);
            this.propagateTouch();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
