import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ValidationMessages } from 'src/app/shared/components/forms/form/form.config';

@Component({
    selector: 'app-forms-textarea',
    templateUrl: './textarea.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true,
        },
    ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() placeholder: string;
    @Input() big = false;
    @Input() small = false;
    @Input() validationMessages: ValidationMessages;

    control: FormControl;

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        if (value) {
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }
}
