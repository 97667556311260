import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    AppMessagePayload,
    SseEventType,
    StreamTokenResponseDto,
} from 'src/app/core/services/dog-vacances-api/sse/sse.dtos';
import { getActionFromEventType } from 'src/app/core/services/dog-vacances-api/sse/sse.mapping';
import { environment } from 'src/environments/environment';

@Injectable()
export class SseService {
    baseUrl = environment.api.sseUrl;
    events$ = new Subject<AppMessagePayload>();
    streamToken: string;
    eventSource: EventSource;

    constructor(private http: HttpClient, private store: Store) {
        this.events$.subscribe((payload) => {
            const action = getActionFromEventType(payload.type);
            this.store.dispatch(action({ payload }));
        });
    }

    getToken(): Observable<StreamTokenResponseDto> {
        return this.http.get<StreamTokenResponseDto>(`${this.baseUrl}/token`);
    }

    async register(): Promise<void> {
        if (this.eventSource) {
            this.eventSource.close();
        }

        this.streamToken = this.streamToken || (await this.getToken().toPromise()).token;
        this.eventSource = new EventSource(`${this.baseUrl}/register/${this.streamToken}`);

        this.eventSource.onopen = (): void => {
            console.log('SSE opened');
        };

        this.eventSource.onmessage = (event: MessageEvent): void => {
            this.events$.next(JSON.parse(event.data));
        };

        this.eventSource.onerror = (event): void => {
            this.streamToken = null;
            void this.register();
        };
    }

    getEvents$(type?: SseEventType): Observable<AppMessagePayload> {
        return this.events$.pipe(filter((event) => !type || event.type === type));
    }
}
