/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'pet-sitting',
        loadChildren: () =>
            import('./features/pet-sitting/pet-sitting.module').then((m) => m.PetSittingModule),
    },
    {
        path: 'user',
        loadChildren: () => import('./features/user/user.module').then((m) => m.UserModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
