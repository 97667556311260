import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ReviewResponseDto,
    SearchPetSitterDto,
    SearchResponseDto,
} from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dto';
import { environment } from 'src/environments/environment';

@Injectable()
export class PetSittersService {
    private baseUrl = environment.api.baseUrl + '/pet-sitting';

    constructor(private http: HttpClient) {}

    search(page: number, limit: number, dto: SearchPetSitterDto): Observable<SearchResponseDto> {
        return this.http.post<SearchResponseDto>(`${this.baseUrl}/search/${page}/${limit}`, dto);
    }

    getReviews(
        petSitterId: string,
        page: number,
        limit: number,
    ): Observable<Pagination<ReviewResponseDto>> {
        return this.http.get<Pagination<ReviewResponseDto>>(
            `${this.baseUrl}/reviews/${petSitterId}/${page}/${limit}`,
        );
    }
}
