import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SHARED_COMPONENTS } from 'src/app/shared/components';
import { SHARED_DIRECTIVES } from 'src/app/shared/directives';
import { SHARED_PIPES } from 'src/app/shared/pipes';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AgmCoreModule } from '@agm/core';
import { SHARED_FORMS } from 'src/app/shared/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule.forRoot({
            hardwareBackButton: false,
            statusTap: false,
        }),
        ReactiveFormsModule.withConfig({
            warnOnNgModelWithFormControl: 'never',
        }),
        TranslateModule,
        AngularSvgIconModule,
        NgxSliderModule,
        AgmCoreModule,
    ],
    declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
    providers: [...SHARED_FORMS],
    exports: [
        /**
         * Modules
         */
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        TranslateModule,
        AngularSvgIconModule,
        NgxSliderModule,
        AgmCoreModule,

        /**
         * Components
         */
        ...SHARED_COMPONENTS,

        /**
         * Directives
         */
        ...SHARED_DIRECTIVES,

        /**
         * Pipes
         */
        ...SHARED_PIPES,
    ],
})
export class SharedModule {}
