import { PipeTransform, Pipe } from '@angular/core';
import dayjs from 'dayjs';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { BookingReviewResponseDto } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';

@Pipe({ name: 'bookingDescription' })
export class BookingDescriptionPipe implements PipeTransform {
    transform(booking: BookingResponseDto | BookingReviewResponseDto): string {
        if (!booking) {
            return null;
        }

        const { service, dates, date, startTime, endTime, duration } = booking;

        if (service === 'ACCOMMODATION') {
            const { start, end } = dates;

            return `Hébergement, du ${this._getDateTxt(start)} au ${this._getDateTxt(end)}`;
        } else if (service === 'HOME_CARE' || service === 'NURSERY') {
            const serviceTxt = service === 'HOME_CARE' ? 'Garde à domicile' : 'Garderie';

            return [
                `${serviceTxt}, le ${this._getDateTxt(date)}`,
                `de ${this._getTimeTxt(startTime)}`,
                `à ${this._getTimeTxt(endTime)}`,
            ].join(' ');
        } else if (service === 'WALK') {
            return `Promenade, le ${this._getDateTxt(date)} (${duration} minutes)`;
        }
    }

    private _getDateTxt(date: string): string {
        return dayjs(date).format('D MMMM YYYY');
    }

    private _getTimeTxt(time: string): string {
        return time.replace(':', 'h');
    }
}
