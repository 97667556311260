import { createAction, props } from '@ngrx/store';

const domain = '[SETTINGS]';

const setShowNotificationsInstructions = createAction(
    `${domain} set showNotificationsInstructions`,
    props<{ showNotificationsInstructions: boolean }>(),
);

export const SettingsActions = { setShowNotificationsInstructions };
