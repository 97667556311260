import { BookingDescriptionPipe } from 'src/app/shared/pipes/booking-description.pipe';
import { ChatMessageTimePipe } from 'src/app/shared/pipes/chat-message-time.pipe';
import { DisplayNamePipe } from 'src/app/shared/pipes/display-name.pipe';
import { DistancePipe } from 'src/app/shared/pipes/distance.pipe';
import { PetDescriptionPipe } from 'src/app/shared/pipes/pet-description.pipe';
import { PetGetAlongPipe } from 'src/app/shared/pipes/pet-get-along.pipe';
import { PetTypePipe } from 'src/app/shared/pipes/pet-type.pipe';
import { PetWeightPipe } from 'src/app/shared/pipes/pet-weight-pipe';
import { RandomPipe } from 'src/app/shared/pipes/random.pipe';
import { TimesPipe } from 'src/app/shared/pipes/times.pipe';

export const SHARED_PIPES = [
    TimesPipe,
    PetTypePipe,
    RandomPipe,
    DistancePipe,
    DisplayNamePipe,
    BookingDescriptionPipe,
    PetDescriptionPipe,
    PetWeightPipe,
    PetGetAlongPipe,
    ChatMessageTimePipe,
];
