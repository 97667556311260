import { createReducer, on } from '@ngrx/store';
import { PetSitterActions } from 'src/stores/pet-sitter/pet-sitter.actions';
import { PetSitterState } from 'src/stores/pet-sitter/pet-sitter.state';

export const initialState: PetSitterState = {
    profile: null,
    services: null,
    availabilities: null,
    reviews: null,
};

export const petSitterReducer = createReducer(
    initialState,
    on(PetSitterActions.loadProfileSuccess, (state, { profile }) => ({ ...state, profile })),
    on(PetSitterActions.editProfileSuccess, (state, { profile }) => ({ ...state, profile })),
    on(PetSitterActions.loadServicesSuccess, (state, { services }) => ({ ...state, services })),
    on(PetSitterActions.editServicesSuccess, (state, { services }) => ({ ...state, services })),
    on(PetSitterActions.loadAvailabilitiesSuccess, (state, { availabilities }) => ({
        ...state,
        availabilities,
    })),
    on(PetSitterActions.updateAvailabilitiesSuccess, (state, { availabilities }) => ({
        ...state,
        availabilities,
    })),
    on(PetSitterActions.loadReviewsSuccess, (state, { reviews }) => ({ ...state, reviews })),
    on(PetSitterActions.seeReviews, (state) => {
        let reviews = state.reviews || [];
        reviews = reviews.map((item) => ({ ...item, seen: true }));

        return { ...state, reviews };
    }),
    on(PetSitterActions.clear, () => ({ ...initialState })),
);
