import { PipeTransform, Pipe } from '@angular/core';
import { PetType } from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';

@Pipe({ name: 'petGetAlong' })
export class PetGetAlongPipe implements PipeTransform {
    transform(getAlong: boolean | null): string {
        if (getAlong === null) {
            return 'Ne sait pas';
        }

        return getAlong ? 'Oui' : 'Non';
    }
}
