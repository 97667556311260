import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

interface FakeInputValue {
    value: unknown;
    label: string;
}
@Component({
    selector: 'app-forms-fake-input',
    templateUrl: './fake-input.component.html',
    styleUrls: ['./fake-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FakeInputComponent),
            multi: true,
        },
    ],
})
export class FakeInputComponent implements OnInit, ControlValueAccessor {
    @Input() placeholder?: string;
    @Input() icon?: string;
    @Input() color?: 'red' | 'yellow';
    @Input() formControlName: string;

    control: FormControl;
    value: FakeInputValue;

    propagateChange: (_: FakeInputValue) => void;
    propagateTouch: () => void;

    constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: FakeInputValue) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: FakeInputValue): void {
        if (value) {
            this.value = value;
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }
}
