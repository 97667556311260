import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { DateRangeDto } from 'src/app/shared/dtos/date-range.dto';
import { HapticService } from 'src/app/core/services/haptic.service';
import { CalendarService, Month } from 'src/app/core/services/calendar.service';

@Component({
    templateUrl: './calendar-modal.component.html',
    styleUrls: ['./calendar-modal.component.scss'],
})
export class CalendarModalComponent implements OnInit, OnDestroy {
    @Input() title = 'Dates';
    @Input() monthsToDisplay = 12;
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() selectedDays: string[] = [];
    @Input() rangeMode = false;
    @Input() multipleMode = false;
    @Input() selectedColor: 'lightPurple' | 'red' | 'yellow' = 'yellow';
    @Input() color: 'lightPurple' | 'red' = 'lightPurple';
    @Input() showCurrent = false;
    @Input() disableNonAvailableDates = false;
    @Input() availabilities: string[];

    onDestroy$ = new Subject<void>();

    months: Month[] = [];

    daysOfWeek = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

    constructor(
        private modalService: ModalService,
        private haptic: HapticService,
        private calendarService: CalendarService,
    ) {}

    ngOnInit(): void {
        this.months = this.calendarService.getMonthsToDisplay(this.monthsToDisplay);
    }

    close(): void {
        this.modalService.dismissIonModal();
    }

    select(day: string): void {
        this.haptic.lightImpact();

        if (this.rangeMode) {
            if (!this.startDate || (this.startDate && this.endDate)) {
                this.startDate = day;
                this.endDate = null;
            } else {
                if (day <= this.startDate) {
                    this.startDate = day;
                    this.endDate = null;
                } else {
                    this.endDate = day;
                }
            }
        } else if (this.multipleMode) {
            for (const selected of this.selectedDays) {
                if (selected === day) {
                    this.selectedDays = this.selectedDays.filter((item) => item !== day);

                    return;
                }
            }
            this.selectedDays.push(day);
        } else {
            this.startDate = day;
        }
    }

    submit(): void {
        this.haptic.mediumImpact();

        if (this.rangeMode) {
            this.modalService.dismissIonModal<DateRangeDto>({
                start: this.startDate,
                end: this.endDate,
            });
        } else if (this.multipleMode) {
            this.modalService.dismissIonModal<string[]>(this.selectedDays);
        } else {
            this.modalService.dismissIonModal<string>(this.startDate);
        }
    }

    canSubmit(): boolean {
        return (
            (this.rangeMode && !!this.startDate && !!this.endDate) ||
            (!this.rangeMode && !!this.startDate) ||
            this.multipleMode
        );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
