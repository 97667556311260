import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { HapticService } from 'src/app/core/services/haptic.service';
import dayjs from 'dayjs';
import $ from 'jquery';
import { ExpandedSelectOption } from 'src/app/shared/components/forms/expanded-select/expanded-select.config';

@Component({
    selector: 'app-forms-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true,
        },
    ],
})
export class TimePickerComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    @ViewChild('carousel') carouselEl: ElementRef;

    @Input() formControlName: string;
    @Input() step = 30;

    onDestroy$ = new Subject<void>();

    options: ExpandedSelectOption[];
    control: FormControl;
    selected: ExpandedSelectOption;
    firstItem = 0;

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private haptic: HapticService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            if (!this.formControlName) {
                throw new Error('formControlName must be defined!');
            }
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }

        this.options = [];
        let start = dayjs().startOf('day');
        const end = dayjs().endOf('day');
        do {
            const value = start.format('HH:mm');
            this.options.push({ value, name: value });

            start = start.add(this.step, 'minutes');

            if (start.get('hour') < 12) {
                ++this.firstItem;
            }
        } while (start < end);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const carousel = $(this.carouselEl.nativeElement);
            const items = carousel.find('> div');
            let scrollLeft = 0;
            for (let i = 0; i < this.firstItem; ++i) {
                scrollLeft += $(items[i]).outerWidth(true);
            }
            carousel.scrollLeft(scrollLeft);
        }, 100);
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: unknown): void {
        this.control?.setValue(value, { emitEvent: false });
        if (value) {
            this.selected = this.options.find((item) => item.value === value);
            this.control?.markAsTouched();

            const values = this.options.map((item) => item.value);
            this.firstItem = values.indexOf(this.selected.value);
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    select(option: ExpandedSelectOption): void {
        this.haptic.lightImpact();
        this.selected = option;
        this.propagateChange(option.value);
        this.control.markAsTouched();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
