import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable()
export class LocalStorageService {
    async set<T = unknown>(key: string, value: T): Promise<void> {
        await Storage.set({
            key,
            value: JSON.stringify(value),
        });
    }

    async get<T = unknown>(key: string): Promise<T> {
        const { value } = await Storage.get({ key });

        return JSON.parse(value) as T;
    }

    async remove(key: string): Promise<void> {
        await Storage.remove({ key });
    }
}
