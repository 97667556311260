import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BookingsService } from 'src/app/core/services/dog-vacances-api/bookings/bookings.service';
import { getStatusesByTabAndClientType } from 'src/app/features/user/pages/bookings';
import { Utils } from 'src/app/shared/utils';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { ClientActions } from 'src/stores/client/client.actions';
import { ErrorsActions } from 'src/stores/errors/errors.actions';
import { PetSitterActions } from 'src/stores/pet-sitter/pet-sitter.actions';

@Injectable()
export class BookingsEffects {
    /**
     * Load bookings
     */

    loadBookings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.loadBookings),
            mergeMap(({ clientType }) =>
                this.bookingsService
                    .getBookings(clientType)
                    .pipe(
                        map((bookings) =>
                            BookingsActions.loadBookingsSuccess({ clientType, bookings }),
                        ),
                    ),
            ),
        ),
    );

    /**
     * Fetch and update bookings
     */

    fetchAndUpdateBookings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.fetchAndUpdateBookings),
            mergeMap(({ clientType, bookingId }) =>
                this.bookingsService
                    .getBooking(bookingId)
                    .pipe(
                        map((booking) => BookingsActions.updateBookings({ clientType, booking })),
                    ),
            ),
        ),
    );

    /**
     * See bookings
     */

    seeBookings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BookingsActions.seeBookings),
                mergeMap(({ clientType, tab }) => {
                    const statuses = getStatusesByTabAndClientType(clientType, tab);

                    return this.bookingsService.seeBookings({
                        type: clientType === 'client' ? 'CLIENT' : 'PET_SITTER',
                        statuses,
                    });
                }),
            ),
        { dispatch: false },
    );

    seeMessages$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BookingsActions.seeMessages),
                mergeMap(({ booking }) => {
                    return this.bookingsService.seeMessages(booking.id);
                }),
            ),
        { dispatch: false },
    );

    /**
     * Approve contact request
     */

    approveContactRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.approveContactRequest),
            mergeMap(({ booking }) =>
                this.bookingsService.approveContactRequest(booking.id).pipe(
                    map((booking) => BookingsActions.approveContactRequestSuccess({ booking })),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);
                            if (code === 'WRONG_STATUS') {
                                return of(
                                    BookingsActions.approveContactRequestError({ error: code }),
                                );
                            }
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    approveContactRequestSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.approveContactRequestSuccess),
            mergeMap(({ booking }) => [
                BookingsActions.updateBookings({ clientType: 'pet-sitter', booking }),
            ]),
        ),
    );

    approveContactRequestError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.approveContactRequestError),
            mergeMap(() => [BookingsActions.loadBookings({ clientType: 'pet-sitter' })]),
        ),
    );

    /**
     * Approve Pet-Sitting
     */

    approvePetSitting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.approvePetSitting),
            mergeMap(({ clientType, booking }) =>
                this.bookingsService.approvePetSitting(clientType, booking.id).pipe(
                    map((booking) =>
                        BookingsActions.approvePetSittingSuccess({ clientType, booking }),
                    ),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);
                            if (code === 'WRONG_STATUS') {
                                return of(
                                    BookingsActions.approvePetSittingError({
                                        clientType,
                                        error: code,
                                    }),
                                );
                            }
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    approvePetSittingSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.approvePetSittingSuccess),
            mergeMap(({ clientType, booking }) => [
                BookingsActions.updateBookings({ clientType, booking }),
            ]),
        ),
    );

    approvePetSittingError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.approvePetSittingError),
            mergeMap(({ clientType }) => [BookingsActions.loadBookings({ clientType })]),
        ),
    );

    /**
     * Cancel Pet-Sitting
     */

    cancelPetSitting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.cancelPetSitting),
            mergeMap(({ clientType, booking, dto }) =>
                this.bookingsService.cancelPetSitting(booking.id, dto).pipe(
                    map((booking) =>
                        BookingsActions.cancelPetSittingSuccess({ clientType, booking }),
                    ),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);
                            if (code === 'WRONG_STATUS') {
                                return of(
                                    BookingsActions.cancelPetSittingError({
                                        clientType,
                                        error: code,
                                    }),
                                );
                            }
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    cancelPetSittingSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.cancelPetSittingSuccess),
            mergeMap(({ clientType, booking }) => [
                BookingsActions.updateBookings({ clientType, booking }),
            ]),
        ),
    );

    cancelPetSittingError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.cancelPetSittingError),
            mergeMap(({ clientType }) => [BookingsActions.loadBookings({ clientType })]),
        ),
    );

    /**
     * Create review
     */

    createReview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.createReview),
            mergeMap(({ booking, dto }) =>
                this.bookingsService.createReview(booking.id, dto).pipe(
                    map((booking) => BookingsActions.createReviewSuccess({ booking })),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);
                            if (code === 'WRONG_STATUS') {
                                return of(BookingsActions.createReviewError({ error: code }));
                            }
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    createReviewSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.createReviewSuccess),
            mergeMap(({ booking }) => [
                BookingsActions.updateBookings({ clientType: 'client', booking }),
            ]),
        ),
    );

    createReviewError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.createReviewError),
            mergeMap(() => [BookingsActions.loadBookings({ clientType: 'client' })]),
        ),
    );

    /**
     * Create chat mesage
     */

    createChatMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.createChatMessage),
            mergeMap(({ clientType, booking, dto }) =>
                this.bookingsService.createChatMessage(booking.id, dto).pipe(
                    map((chatMessage) => {
                        const messages = [...booking.messages];
                        messages.push(chatMessage);

                        return BookingsActions.createChatMessageSuccess({
                            clientType,
                            booking: { ...booking, messages },
                        });
                    }),
                ),
            ),
        ),
    );

    createChatMessageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookingsActions.createChatMessageSuccess),
            mergeMap(({ clientType, booking }) => [
                BookingsActions.updateBookings({ clientType, booking }),
            ]),
        ),
    );

    constructor(private actions$: Actions, private bookingsService: BookingsService) {}
}
