import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ValidationMessages } from 'src/app/shared/components/forms/form/form.config';
import { InputType } from 'src/app/shared/components/forms/input/input.config';

@Component({
    selector: 'app-forms-input',
    templateUrl: './input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() placeholder: string;
    @Input() icon: string;
    @Input() type: InputType = 'text';
    @Input() validationMessages: ValidationMessages;
    @Input() inputMode: 'text' | 'numeric' | 'tel' | 'email' = 'text';
    @Input() labelHint?: string;
    @Input() labelColor: 'black' | 'red' = 'black';

    control: FormControl;

    propagateChange: (_: string) => void;
    propagateTouch: () => void;

    constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: string): void {
        if (value) {
            // this.control.setValue(value, { emitEvent: false });
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }
}
