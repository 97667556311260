import { createReducer, on } from '@ngrx/store';
import dayjs from 'dayjs';
import { getStatusesByTabAndClientType } from 'src/app/features/user/pages/bookings';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { BookingsState } from 'src/stores/bookings/bookings.state';

export const initialState: BookingsState = {
    clientBookings: null,
    petSitterBookings: null,
};

export const bookingsReducer = createReducer(
    initialState,
    on(BookingsActions.loadBookingsSuccess, (state, { clientType, bookings }) => {
        return {
            ...state,
            ...(clientType === 'client'
                ? { clientBookings: bookings }
                : { petSitterBookings: bookings }),
        };
    }),
    on(BookingsActions.updateBookings, (state, { clientType, booking }) => {
        let bookings =
            (clientType === 'client' ? state.clientBookings : state.petSitterBookings) || [];
        bookings = bookings.filter((item) => item.id !== booking.id);
        bookings.push(booking);
        bookings.sort((a, b) => b.lastUpdate - a.lastUpdate);

        if (clientType === 'client') {
            return { ...state, clientBookings: bookings };
        } else if (clientType === 'pet-sitter') {
            return { ...state, petSitterBookings: bookings };
        }
    }),
    on(BookingsActions.removeBooking, (state, { bookingId }) => {
        return {
            state,
            clientBookings: state.clientBookings.filter((item) => item.id !== bookingId),
            petSitterBookings: state.petSitterBookings.filter((item) => item.id !== bookingId),
        };
    }),
    on(BookingsActions.seeBookings, (state, { clientType, tab }) => {
        const statuses = getStatusesByTabAndClientType(clientType, tab);
        let bookings =
            (clientType === 'client' ? state.clientBookings : state.petSitterBookings) || [];
        bookings = bookings.map((item) => {
            if (statuses.includes(item.status)) {
                return {
                    ...item,
                    ...(clientType === 'client'
                        ? { seenByClient: true }
                        : { seenByPetSitter: true }),
                };
            }

            return item;
        });

        return {
            ...state,
            ...(clientType === 'client'
                ? { clientBookings: bookings }
                : { petSitterBookings: bookings }),
        };
    }),
    on(BookingsActions.seeMessages, (state, { clientType, booking }) => {
        const bookings = (clientType === 'client'
            ? state.clientBookings
            : state.petSitterBookings
        ).map((item) => {
            if (item.id === booking.id) {
                const messages = item.messages.map((message) => {
                    return { ...message, seen: true };
                });

                return { ...item, messages };
            }

            return item;
        });

        return {
            ...state,
            ...(clientType === 'client'
                ? { clientBookings: bookings }
                : { petSitterBookings: bookings }),
        };
    }),
    on(
        BookingsActions.newMessage,
        (state, { payload: { clientType, bookingId, message, lastUpdate } }) => {
            const bookings = (clientType === 'client'
                ? state.clientBookings
                : state.petSitterBookings
            ).map((item) => {
                if (item.id === bookingId) {
                    console.log('lastUpdate', lastUpdate);

                    return { ...item, messages: [...item.messages, message], lastUpdate };
                }

                return item;
            });

            bookings.sort((a, b) => b.lastUpdate - a.lastUpdate);

            return {
                ...state,
                ...(clientType === 'client'
                    ? { clientBookings: bookings }
                    : { petSitterBookings: bookings }),
            };
        },
    ),
    on(BookingsActions.clear, () => ({ ...initialState })),
);
