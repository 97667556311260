import { Component, Input } from '@angular/core';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent {
    @Input()
    set large(value: unknown) {
        this.isLarge = convertToBoolProperty(value);
    }

    @Input()
    set noMarginTop(value: unknown) {
        this.hasMarginTop = !convertToBoolProperty(value);
    }

    isLarge = false;
    hasMarginTop = true;
}
