import { PipeTransform, Pipe } from '@angular/core';
import { PetReviewResponseDto } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';
import { PetResponseDto } from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';

@Pipe({ name: 'petDescription' })
export class PetDescriptionPipe implements PipeTransform {
    transform(pet: PetResponseDto | PetReviewResponseDto): string {
        const { name, type, breed } = pet;

        return `${name} (${type === 'CAT' ? 'Chat' : 'Chien'} - ${breed})`;
    }
}
