import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { MessagingTab } from 'src/app/features/user/guards/messaging-tab.guard';
import { MenuComponent, MenuItem } from 'src/app/shared/components/menu/menu.component';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { AuthSelectors } from 'src/stores/auth/auth.selectors';
import { BookingsSelectors } from 'src/stores/bookings/bookings.selectors';

@Component({
    templateUrl: './messaging.page.html',
    styleUrls: ['./messaging.page.scss'],
})
export class MessagingPage {
    @ViewChild('menu') menu: MenuComponent;
    @ViewChild('slider') slider: IonSlides;

    type: ClientType;
    activeIndex: number;

    menuItems: MenuItem[] = [];

    slideOpts = { autoHeight: true, initialSlide: 0 };
    isPetSitter = this.store.select(AuthSelectors.selectIsPetSitter);

    clientMessagingBadge = this.store.select(BookingsSelectors.selectMessagingBadge('client'));
    petSitterMessagingBadge = this.store.select(
        BookingsSelectors.selectMessagingBadge('pet-sitter'),
    );

    constructor(private route: ActivatedRoute, private store: Store) {
        this.route.params.subscribe((params) => {
            this.type = params.type as ClientType;

            this.menuItems = [
                {
                    name: 'En attente',
                    icon: 'bookings/waiting-icon',
                    badge: of(0),
                    selectedColor: this.color,
                },
                {
                    name: 'En cours',
                    icon: 'bookings/valid-icon',
                    badge:
                        this.type === 'client'
                            ? this.clientMessagingBadge
                            : this.petSitterMessagingBadge,
                    selectedColor: this.color,
                },
            ];

            if (params.tab) {
                setTimeout(() => {
                    this._changeTab(params.tab);
                });
            }
        });
    }

    get color(): 'red' | 'lightPurple' {
        return this.type === 'client' ? 'lightPurple' : 'red';
    }

    async ionSlideWillChange(): Promise<void> {
        const active = await this.slider.getActiveIndex();
        this.menu.setActive(active);
        this.activeIndex = active;
    }

    private _changeTab(tab: MessagingTab): void {
        const tabs: MessagingTab[] = ['WAITING', 'IN_PROGRESS'];
        void this.slider.slideTo(tabs.indexOf(tab));
    }
}
