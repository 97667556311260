import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { GeolocationService } from 'src/app/core/services/geolocation.service';
import { LocationActions } from 'src/stores/location/location.actions';

@Injectable()
export class LocationEffects {
    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.update),
            mergeMap(({ openSettingsIfDenied }) => {
                return from(this.geolocationService.update(openSettingsIfDenied)).pipe(
                    map((coords) => {
                        return LocationActions.updateSuccess({
                            coords,
                            status: coords ? 'ENABLED' : 'DISABLED',
                        });
                    }),
                );
            }),
        );
    });

    debugMode$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.debugMode),
            mergeMap(() => {
                return of(this.geolocationService.debugMode()).pipe(
                    map((coords) => {
                        return LocationActions.updateSuccess({ coords, status: 'DEBUG' });
                    }),
                );
            }),
        );
    });

    constructor(private actions$: Actions, private geolocationService: GeolocationService) {}
}
