import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

export interface MenuItem {
    icon: string;
    name: string;
    badge: Observable<number>;
    selectedColor: 'yellow' | 'lightPurple' | 'red';
}

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    @Input() menuItems: MenuItem[];
    @Input() badgeColor: 'lightPurple' | 'red';
    @Output() onChange = new EventEmitter<number>();

    activeIndex = 0;

    setActive(index: number): void {
        this.activeIndex = index;
        this.onChange.emit(index);
    }
}
