import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    DeviceDto,
    UnregisterDeviceDto,
} from 'src/app/core/services/dog-vacances-api/device/device.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class DeviceService {
    private baseUrl = environment.api.baseUrl + '/device';

    constructor(private http: HttpClient) {}

    register(dto: DeviceDto): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/register`, dto);
    }

    unregister(dto: UnregisterDeviceDto): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/unregister`, dto);
    }
}
