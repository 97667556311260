import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { API_SERVICES } from 'src/app/core/services/dog-vacances-api';
import { GeolocationService } from 'src/app/core/services/geolocation.service';
import { GooglePlacesService } from 'src/app/core/services/google-place/google-place.service';
import { HapticService } from 'src/app/core/services/haptic.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { PushNotificationService } from 'src/app/core/services/push-notifications.service';
import { ToastService } from 'src/app/core/services/toast.service';

export const CORE_SERVICES = [
    ...API_SERVICES,
    ModalService,
    LoaderService,
    GeolocationService,
    ToastService,
    GooglePlacesService,
    LocalStorageService,
    HapticService,
    CalendarService,
    AuthService,
    PushNotificationService,
];
