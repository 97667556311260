import {
    Component,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HapticService } from 'src/app/core/services/haptic.service';
import { ExpandedSelectOption } from 'src/app/shared/components/forms/expanded-select/expanded-select.config';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-forms-expanded-select',
    templateUrl: './expanded-select.component.html',
    styleUrls: ['./expanded-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExpandedSelectComponent),
            multi: true,
        },
    ],
})
export class ExpandedSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() options: ExpandedSelectOption[];
    @Input() formControlName: string;
    @Input() set big(value: unknown) {
        this.isBig = convertToBoolProperty(value);
    }
    @Input() color: 'yellow' | 'red' = 'yellow';

    @Output() touched = new EventEmitter<void>();

    onDestroy$ = new Subject<void>();

    control: FormControl;
    selected: ExpandedSelectOption;

    isBig = false;

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private haptic: HapticService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            if (!this.formControlName) {
                throw new Error('formControlName must be defined!');
            }
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
        this.touched.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.propagateTouch());
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: unknown): void {
        this.selected = this.options.find((item) => item.value === value);
        if (this.selected) {
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    select(option: ExpandedSelectOption): void {
        this.haptic.lightImpact();
        this.selected = option;
        this.propagateChange(option.value);
        this.control.markAsTouched();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
