import { PipeTransform, Pipe } from '@angular/core';
import { PetResponseDto, PetWeight } from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';

@Pipe({ name: 'petWeight' })
export class PetWeightPipe implements PipeTransform {
    transform(weight: PetWeight): string {
        if (weight === 'LESS_THAN_7') {
            return 'Moins de 7 kg';
        } else if (weight === 'BETWEEN_7_18') {
            return 'Entre 7 et 18 kg';
        } else if (weight === 'BETWEEN_18_45') {
            return 'Entre 18 et 45 kg';
        } else if (weight === 'MORE_THAN_45') {
            return 'Plus de 45 kg';
        }
    }
}
