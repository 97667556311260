import { AddressModalComponent } from 'src/app/shared/components/forms/address/address-modal/address-modal.component';
import { AddressComponent } from 'src/app/shared/components/forms/address/address.component';
import { CalendarModalComponent } from 'src/app/shared/components/forms/calendar/calendar-modal/calendar-modal.component';
import { CalendarComponent } from 'src/app/shared/components/forms/calendar/calendar.component';
import { CheckboxCardComponent } from 'src/app/shared/components/forms/checkbox-card/checkbox-card.component';
import { CheckboxComponent } from 'src/app/shared/components/forms/checkbox/checkbox.component';
import { ErrorsComponent } from 'src/app/shared/components/forms/errors/errors.component';
import { ExpandedSelectComponent } from 'src/app/shared/components/forms/expanded-select/expanded-select.component';
import { FakeInputComponent } from 'src/app/shared/components/forms/fake-input/fake-input.component';
import { FormComponent } from 'src/app/shared/components/forms/form/form.component';
import { InputComponent } from 'src/app/shared/components/forms/input/input.component';
import { PhoneNumberComponent } from 'src/app/shared/components/forms/phone-number/phone-number.component';
import { PictureComponent } from 'src/app/shared/components/forms/picture/picture.component';
import { PicturesComponent } from 'src/app/shared/components/forms/pictures/pictures.component';
import { RangeComponent } from 'src/app/shared/components/forms/range/range.component';
import { SelectModalComponent } from 'src/app/shared/components/forms/select/select-modal/select-modal.component';
import { SelectComponent } from 'src/app/shared/components/forms/select/select.component';
import { TextareaComponent } from 'src/app/shared/components/forms/textarea/textarea.component';
import { TimePickerComponent } from 'src/app/shared/components/forms/time-picker/time-picker.component';
import { ValueSliderComponent } from 'src/app/shared/components/forms/value-slider/value-slider.component';

export const FORM_COMPONENTS = [
    InputComponent,
    SelectComponent,
    SelectModalComponent,
    ExpandedSelectComponent,
    AddressComponent,
    AddressModalComponent,
    CalendarComponent,
    CalendarModalComponent,
    RangeComponent,
    PhoneNumberComponent,
    CheckboxComponent,
    FakeInputComponent,
    TimePickerComponent,
    FormComponent,
    TextareaComponent,
    ErrorsComponent,
    CheckboxCardComponent,
    PictureComponent,
    PicturesComponent,
    ValueSliderComponent,
];
