import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/stores';
import { PetSittingState } from 'src/stores/pet-sitting/pet-sitting.state';
import { RouterSelectors } from 'src/stores/router/router.selectors';

const selectPetSittingState = createFeatureSelector<AppState, PetSittingState>('petSitting');

const selectSearchParams = createSelector(
    selectPetSittingState,
    ({ searchParams }) => searchParams,
);
const selectPriceRange = createSelector(selectPetSittingState, ({ minPrice, maxPrice }) => ({
    minPrice,
    maxPrice,
}));
const selectCurrentPage = createSelector(selectPetSittingState, ({ currentPage }) => currentPage);
const selectPageInfos = createSelector(selectPetSittingState, ({ currentPage, totalPages }) => ({
    currentPage,
    totalPages,
}));
const selectResults = createSelector(selectPetSittingState, ({ results }) => results);
const selectPetSitter = createSelector(
    selectResults,
    RouterSelectors.selectRouteParam('id'),
    (results, id) => {
        return results ? results.find((item) => item.id === id) : null;
    },
);
const selectContactRequest = createSelector(
    selectPetSittingState,
    ({ contactRequest }) => contactRequest,
);
const contactRequest = {
    selectPetSitterId: createSelector(selectContactRequest, ({ petSitterId }) => petSitterId),
    selectPetSitterPetPreference: createSelector(
        selectContactRequest,
        ({ petSitterPetPreference }) => petSitterPetPreference,
    ),
    selectForm: createSelector(selectContactRequest, ({ form }) => form),
};

export const PetSittingSelectors = {
    selectSearchParams,
    selectPriceRange,
    selectCurrentPage,
    selectPageInfos,
    selectResults,
    selectPetSitter,
    contactRequest,
};
