import { createAction, props } from '@ngrx/store';
import {
    AddEditPetDto,
    PetResponseDto,
} from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';

const domain = '[CLIENT]';

/**
 * Load pets
 */
const loadPets = createAction(`${domain} Load pets`);
const loadPetsSuccess = createAction(
    `${domain} Load pets success`,
    props<{ pets: PetResponseDto[] }>(),
);
const LOAD_PETS_ACTIONS = { loadPets, loadPetsSuccess };

/**
 * Edit pet
 */
const editPet = createAction(`${domain} Edit pet`, props<{ id: string; dto: AddEditPetDto }>());
const editPetSuccess = createAction(`${domain} Edit pet success`, props<{ pet: PetResponseDto }>());
const EDIT_PET_ACTIONS = { editPet, editPetSuccess };

/**
 * Add pet
 */
const addPet = createAction(`${domain} Add pet`, props<{ dto: AddEditPetDto }>());
const addPetSuccess = createAction(`${domain} Add pet success`, props<{ pet: PetResponseDto }>());
const ADD_PET_ACTIONS = { addPet, addPetSuccess };

/**
 * Remove pet
 */
const removePet = createAction(`${domain} Remove pet`, props<{ id: string }>());
const removePetSuccess = createAction(`${domain} Remove pet success`, props<{ id: string }>());
const REMOVE_PET_ACTIONS = { removePet, removePetSuccess };

const clear = createAction(`${domain} Clear`);

const PET_ACTIONS = {
    ...LOAD_PETS_ACTIONS,
    ...EDIT_PET_ACTIONS,
    ...ADD_PET_ACTIONS,
    ...REMOVE_PET_ACTIONS,
    clear,
};

export const ClientActions = {
    ...PET_ACTIONS,
};
