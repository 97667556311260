import { createReducer, on } from '@ngrx/store';
import { ClientActions } from 'src/stores/client/client.actions';
import { ClientState } from 'src/stores/client/client.state';

export const initialState: ClientState = {
    pets: null,
};

export const clientReducer = createReducer(
    initialState,
    on(ClientActions.loadPetsSuccess, (state, { pets }) => ({ ...state, pets, isLoaded: true })),
    on(ClientActions.editPetSuccess, (state, { pet }) => {
        return {
            ...state,
            pets: state.pets.map((item) => {
                return item.id === pet.id ? pet : item;
            }),
        };
    }),
    on(ClientActions.addPetSuccess, (state, { pet }) => {
        const pets = state.pets.filter((item) => item.id !== pet.id);
        pets.push(pet);

        return { ...state, pets };
    }),
    on(ClientActions.removePetSuccess, (state, { id }) => {
        return { ...state, pets: state.pets.filter((item) => item.id !== id) };
    }),
    on(ClientActions.clear, () => ({ ...initialState })),
);
