import { createAction, props } from '@ngrx/store';
import {
    NewContactRequestPayload,
    NewMessagePayload,
    PetSittingApprovedPayload,
    PetSittingCanceledPayload,
    PetSittingFinishedPayload,
    PetSittingRatedPayload,
    NotificationPayload,
    PetSittingExpiredPayload,
} from 'src/app/core/services/dog-vacances-api/sse/sse.dtos';

const domain = '[SSE]';

const newMessage = createAction(`${domain} New message`, props<{ payload: NewMessagePayload }>());
const newContactRequest = createAction(
    `${domain} New contact request`,
    props<{ payload: NewContactRequestPayload }>(),
);
const contactRequestApproved = createAction(
    `${domain} Contact request approved`,
    props<{ payload: NewContactRequestPayload }>(),
);
const petSittingApproved = createAction(
    `${domain} Pet-Sitting approved`,
    props<{ payload: PetSittingApprovedPayload }>(),
);
const petSittingCanceled = createAction(
    `${domain} Pet-Sitting canceled`,
    props<{ payload: PetSittingCanceledPayload }>(),
);
const petSittingExpired = createAction(
    `${domain} Pet-Sitting expired`,
    props<{ payload: PetSittingExpiredPayload }>(),
);
const petSittingFinished = createAction(
    `${domain} Pet-Sitting finished`,
    props<{ payload: PetSittingFinishedPayload }>(),
);
const petSittingRated = createAction(
    `${domain} Pet-Sitting rated`,
    props<{ payload: PetSittingRatedPayload }>(),
);
const notification = createAction(
    `${domain} Notification`,
    props<{ payload: NotificationPayload }>(),
);

export const SseActions = {
    newMessage,
    newContactRequest,
    contactRequestApproved,
    petSittingApproved,
    petSittingCanceled,
    petSittingExpired,
    petSittingFinished,
    petSittingRated,
    notification,
};
