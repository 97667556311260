import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationMessages } from 'src/app/shared/components/forms/form/form.config';

@Component({
    selector: 'app-forms-errors',
    templateUrl: './errors.component.html',
})
export class ErrorsComponent {
    @Input() control: AbstractControl;
    @Input() validationMessages: ValidationMessages;

    get errors(): string[] {
        const errors: string[] = [];
        for (const key in this.control.errors) {
            if (this.validationMessages) {
                const message = this.validationMessages[key];
                if (!message) {
                    continue;
                }
                if (typeof message === 'function') {
                    errors.push(message(this.control));
                } else {
                    errors.push(message);
                }
            }
        }

        return errors;
    }
}
