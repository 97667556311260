import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SseService } from 'src/app/core/services/dog-vacances-api/sse/sse.service';
import { UserService } from 'src/app/core/services/dog-vacances-api/user/user.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { Utils } from 'src/app/shared/utils';
import { AuthActions } from 'src/stores/auth/auth.actions';
import { AuthSelectors } from 'src/stores/auth/auth.selectors';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { ClientActions } from 'src/stores/client/client.actions';
import { ErrorsActions } from 'src/stores/errors/errors.actions';
import { PetSitterActions } from 'src/stores/pet-sitter/pet-sitter.actions';

@Injectable()
export class AuthEffects {
    defaultRedirectUrl = '/home';

    login$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.login),
                mergeMap(() => {
                    void this.navCtrl.navigateForward('/user/auth/login');

                    return of(null);
                }),
            ),
        { dispatch: false },
    );

    signup$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.signup),
                mergeMap(() => {
                    void this.navCtrl.navigateForward('/user/signup');

                    return of(null);
                }),
            ),
        { dispatch: false },
    );

    loginAndSignupSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSuccess, AuthActions.signupSuccess),
                concatLatestFrom(() =>
                    this.store.select(AuthSelectors.selectRedirectUrlAndPetSitterName),
                ),
                mergeMap(([action, { redirectUrl, petSitterName }]) => {
                    if (
                        action.type === '[AUTH] Signup success' &&
                        redirectUrl !== '/user/signup/pet-sitter'
                    ) {
                        void this.modalService.success({
                            title: 'Bienvenue dans la famille DogVacances !',
                            text: [
                                `Merci pour votre inscription. Nous espérons que vous allez profiter pleinement de nos services ! <br> <br>`,
                                petSitterName
                                    ? `Vous êtes désormais connecté, il ne vous reste plus qu'à finaliser votre demande de contact avec <strong>${petSitterName}</strong>`
                                    : `Si vous avez des questions n'hésitez pas à nous contacter ou consulter notre FAQ située dans la partie "Plus" de votre compte`,
                            ].join(''),
                        });
                    }
                    void this.navCtrl.navigateBack(redirectUrl || this.defaultRedirectUrl, {
                        animationDirection: 'forward',
                    });

                    return of(null);
                }),
            ),
        { dispatch: false },
    );

    editUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.editUser),
            mergeMap(({ dto }) =>
                this.userService.editUser(dto).pipe(
                    map((user) => AuthActions.editUserSuccess({ user })),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);
                            if (code === 'USER_ALREADY_EXISTS') {
                                return of(AuthActions.editUserError({ error: code }));
                            }
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loadUser),
            mergeMap(() =>
                this.userService.getInfo().pipe(
                    map((user) => AuthActions.loadUserSuccess({ user })),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);

                            return of(AuthActions.loadUserError({ error: code }));
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    loadUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loadUserSuccess),
            mergeMap(({ user }) => {
                void this.sse.register();
                const actions = [
                    ClientActions.loadPets(),
                    BookingsActions.loadBookings({ clientType: 'client' }),
                    ...(user.isPetSitter
                        ? [
                              PetSitterActions.loadProfile(),
                              PetSitterActions.loadServices(),
                              PetSitterActions.loadAvailabilities(),
                              BookingsActions.loadBookings({ clientType: 'pet-sitter' }),
                              PetSitterActions.loadReviews(),
                          ]
                        : []),
                ];

                return actions;
            }),
        ),
    );

    logoutSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logoutSuccess),
            mergeMap(() => [
                ClientActions.clear(),
                PetSitterActions.clear(),
                BookingsActions.clear(),
            ]),
        ),
    );

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private store: Store,
        private navCtrl: NavController,
        private sse: SseService,
        private modalService: ModalService,
    ) {}
}
