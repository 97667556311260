import { createReducer, on } from '@ngrx/store';
import { LocationActions } from 'src/stores/location/location.actions';
import { LocationState } from 'src/stores/location/location.state';

export const initialState: LocationState = {
    status: null,
    coords: null,
};

export const locationReducer = createReducer(
    initialState,
    on(LocationActions.updateSuccess, (state, { coords, status }) => ({
        ...state,
        coords,
        status,
    })),
);
