import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BookingsService } from 'src/app/core/services/dog-vacances-api/bookings/bookings.service';
import { PetSittersService } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.service';
import { Utils } from 'src/app/shared/utils';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { ClientActions } from 'src/stores/client/client.actions';
import { ErrorsActions } from 'src/stores/errors/errors.actions';
import { PetSittingActions } from 'src/stores/pet-sitting/pet-sitting.actions';
import { PetSittingSelectors } from 'src/stores/pet-sitting/pet-sitting.selectors';

@Injectable()
export class PetSittingEffects {
    resultsPerPage = 10;

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSittingActions.search),
            concatLatestFrom(() => this.store.select(PetSittingSelectors.selectSearchParams)),
            concatLatestFrom(() => this.store.select(PetSittingSelectors.selectCurrentPage)),
            mergeMap(([[_, searchParams], page]) => {
                return this.petSittersService.search(page, this.resultsPerPage, searchParams).pipe(
                    map(({ items, meta: { totalPages }, minPrice, maxPrice }) =>
                        PetSittingActions.searchSuccess({
                            results: items,
                            totalPages,
                            minPrice,
                            maxPrice,
                        }),
                    ),
                );
            }),
        ),
    );

    loadMore$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSittingActions.loadMore),
            concatLatestFrom(() => this.store.select(PetSittingSelectors.selectSearchParams)),
            concatLatestFrom(() => this.store.select(PetSittingSelectors.selectCurrentPage)),
            mergeMap(([[_, searchParams], page]) =>
                this.petSittersService
                    .search(page, this.resultsPerPage, searchParams)
                    .pipe(
                        map(({ items, meta: { totalPages } }) =>
                            PetSittingActions.loadMoreSuccess({ results: items, totalPages }),
                        ),
                    ),
            ),
        ),
    );

    createContactRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSittingActions.contactRequest.create),
            mergeMap(({ petSitterId, dto }) =>
                this.bookingsService.createContactRequest(petSitterId, dto).pipe(
                    map((booking) => PetSittingActions.contactRequest.createSuccess({ booking })),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 400) {
                            const code = Utils.getHttpErrorCode(error);
                            if (code === 'SERVICE_NOT_AVAILABLE') {
                                return of(
                                    PetSittingActions.contactRequest.createError({ error: code }),
                                );
                            }
                        }

                        return of(ErrorsActions.httpError({ error }));
                    }),
                ),
            ),
        ),
    );

    createContactRequestSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSittingActions.contactRequest.createSuccess),
            mergeMap(({ booking }) => [
                PetSittingActions.contactRequest.clear(),
                BookingsActions.updateBookings({ clientType: 'client', booking }),
            ]),
        ),
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private petSittersService: PetSittersService,
        private bookingsService: BookingsService,
    ) {}
}
