import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { ModalService } from 'src/app/core/services/modal.service';
import { CancelModalComponent } from 'src/app/features/user/components/bookings/cancel-modal/cancel-modal.component';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { BookingsSelectors } from 'src/stores/bookings/bookings.selectors';

@Component({
    templateUrl: './informations.page.html',
    styleUrls: ['./informations.page.scss'],
})
export class InformationsPage implements OnDestroy {
    type: ClientType;

    booking: Observable<BookingResponseDto>;

    onDestroy$ = new Subject<void>();
    isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private modalService: ModalService,
        private store: Store,
        private actions$: Actions,
        private navCtrl: NavController,
    ) {
        this.route.params.subscribe((params) => {
            this.type = params.type as ClientType;
            this.booking = this.store.select(BookingsSelectors.selectBooking(this.type));
        });

        this.actions$
            .pipe(ofType(BookingsActions.approveContactRequestSuccess))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(({ booking }) => {
                this.isLoading = false;
                void this.navCtrl.navigateForward([
                    '/user',
                    'messaging',
                    this.type,
                    'chat',
                    booking.id,
                ]);
            });

        this.actions$
            .pipe(ofType(BookingsActions.approveContactRequestError))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.isLoading = false;
            });
    }

    get color(): 'red' | 'lightPurple' {
        return this.type === 'client' ? 'lightPurple' : 'red';
    }

    cancel(booking: BookingResponseDto): void {
        this.modalService.openModal(CancelModalComponent, { booking, type: this.type });
    }

    async approveContactRequest(booking: BookingResponseDto): Promise<void> {
        const event = await this.modalService.confirm({
            title: 'Confirmation',
            text: `Confirmez-vous l'acceptation de la demande de contact de <span class="text-red">${booking.client.displayName}</span>&nbsp;?`,
            icon: 'assets/images/dog.svg',
            color: 'red',
        });
        if (event === 'CONFIRMED') {
            this.isLoading = true;
            this.store.dispatch(BookingsActions.approveContactRequest({ booking }));
        }
    }

    canContact({ status }: BookingResponseDto): boolean {
        return status !== 'CONTACT_REQUEST';
    }

    contact(booking: BookingResponseDto): void {
        void this.navCtrl.navigateForward(['/user', 'messaging', this.type, 'chat', booking.id]);
    }

    canCancel({ status }: BookingResponseDto): boolean {
        return status === 'APPROVED';
    }

    canApproveContactRequest({ status }: BookingResponseDto): boolean {
        return status === 'CONTACT_REQUEST';
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
