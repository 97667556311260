import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appRemoveHost]',
})
export class RemoveHostDirective implements OnInit {
    constructor(private el: ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        const nativeElement: HTMLElement = this.el.nativeElement;
        const parentElement: HTMLElement = nativeElement.parentElement;

        while (nativeElement.firstChild) {
            parentElement.insertBefore(nativeElement.firstChild, nativeElement);
        }

        parentElement.removeChild(nativeElement);
    }
}
