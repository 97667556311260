import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable()
export class ToastService {
    constructor(private toastController: ToastController) {}

    async present(options: {
        message: string;
        duration?: number;
        color?: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error?: any;
    }): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { message, duration, color, error } = options;

        let errorMessage: string;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error && error.message && !environment.production) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            errorMessage = error.message;
        }

        const toast = await this.toastController.create({
            ...(errorMessage
                ? {
                      message: `${message} (<b>Stacktrace</b> : ${errorMessage})`,
                  }
                : { message }),
            duration: duration || 4000,
            mode: 'ios',
            color: color || 'danger',
            position: 'top',
        });

        void toast.present();
    }
}
