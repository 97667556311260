import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/stores';
import { AuthState } from 'src/stores/auth/auth.state';

const selectAuth = createFeatureSelector<AppState, AuthState>('auth');

const selectIsLogged = createSelector(selectAuth, ({ isLogged }) => isLogged);
const selectUser = createSelector(selectAuth, ({ user }) => user);
const selectIsPetSitter = createSelector(selectAuth, ({ user: { isPetSitter } }) => isPetSitter);
const selectRedirectUrlAndPetSitterName = createSelector(
    selectAuth,
    ({ redirectUrl, petSitterName }) => ({
        redirectUrl,
        petSitterName,
    }),
);

export const AuthSelectors = {
    selectAuth,
    selectIsLogged,
    selectUser,
    selectIsPetSitter,
    selectRedirectUrlAndPetSitterName,
};
