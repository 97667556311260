import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoaderService {
    constructor(private loadingController: LoadingController) {}

    async present(id?: string): Promise<void> {
        const loading = await this.loadingController.create({
            spinner: 'crescent',
            mode: 'ios',
            ...(id ? { id } : {}),
        });
        void loading.present();
    }

    async dismiss(id?: string): Promise<void> {
        try {
            await this.loadingController.dismiss(null, null, id);
        } catch {
            // No loader to dismiss
        }
    }
}
