import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { AnimationController, Animation } from '@ionic/angular';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements AfterViewInit {
    @Input() title: string;
    @Input() text?: string;
    @Input() texts?: string[];
    @Input() icon: string;
    @Input() confirmTxt: string;
    @Input() skipTxt: string;
    @Input() closeIcon = true;
    @Input() autoclose = false;
    @Input() isLoading = false;
    @Input() color: 'lightPurple' | 'red' = 'lightPurple';

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSkip: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('overlay') overlayRef: ElementRef;
    @ViewChild('modal') modalRef: ElementRef;

    animation: Animation;
    animationDuration = 400;

    constructor(private modalService: ModalService, private animationCtrl: AnimationController) {}

    ngAfterViewInit(): void {
        const overlayAnimation: Animation = this.animationCtrl
            .create()
            .addElement(this.overlayRef.nativeElement)
            .easing('ease-in-out')
            .duration(this.animationDuration * 0.5)
            .keyframes([
                { offset: 0, opacity: 0 },
                { offset: 1, opacity: 0.9 },
            ]);

        const modalAnimation: Animation = this.animationCtrl
            .create()
            .addElement(this.modalRef.nativeElement)
            .duration(this.animationDuration)
            .keyframes([
                { offset: 0, transform: 'scale(0)', opacity: 0 },
                { offset: 0.7, transform: 'scale(1.1)' },
                { offset: 1, transform: 'scale(1)', opacity: 1 },
            ]);

        this.animation = this.animationCtrl
            .create()
            .easing('ease-in-out')
            .addAnimation([overlayAnimation, modalAnimation]);

        void this.animation.play();
    }

    async close(): Promise<void> {
        await this.animation.direction('reverse').play();
        this.modalService.closeModal();
    }
}
