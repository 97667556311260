import { createAction, props } from '@ngrx/store';
import {
    EditUserDto,
    UserResponseDto,
} from 'src/app/core/services/dog-vacances-api/user/user.dtos';

const domain = '[AUTH]';

/**
 * Login and signup
 */
const login = createAction(
    `${domain} Login`,
    props<{ url?: string | string[]; petSitterName?: string }>(),
);
const signup = createAction(`${domain} Signup`, props<{ url?: string | string[] }>());
const loginSuccess = createAction(`${domain} Login success`);
const signupSuccess = createAction(`${domain} Signup success`);
const logoutSuccess = createAction(`${domain} Logout`);
const LOGIN_AND_SIGNUP_ACTIONS = {
    login,
    signup,
    loginSuccess,
    signupSuccess,
    logoutSuccess,
};

/**
 * Edit user
 */
const editUser = createAction(`${domain} Edit user`, props<{ dto: EditUserDto }>());
const editUserSuccess = createAction(
    `${domain} Edit user success`,
    props<{ user: UserResponseDto }>(),
);
const editUserError = createAction(`${domain} Edit user error`, props<{ error: string }>());
const EDIT_USER_ACTIONS = { editUser, editUserSuccess, editUserError };

/**
 * Load User
 */
const loadUser = createAction(`${domain} Load user`);
const loadUserSuccess = createAction(
    `${domain} Load user success`,
    props<{ user: UserResponseDto }>(),
);
const loadUserError = createAction(`${domain} Edit user error`, props<{ error: string }>());
const LOAD_USER_ACTIONS = { loadUser, loadUserSuccess, loadUserError };

export const AuthActions = {
    ...LOGIN_AND_SIGNUP_ACTIONS,
    ...EDIT_USER_ACTIONS,
    ...LOAD_USER_ACTIONS,
};
