import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { BookingTab } from 'src/app/features/user/guards/booking-tab.guard';
import { getBookingTabByStatus } from 'src/app/features/user/pages/bookings';
import { MenuComponent, MenuItem } from 'src/app/shared/components/menu/menu.component';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import { BookingsSelectors } from 'src/stores/bookings/bookings.selectors';

@Component({
    templateUrl: './bookings.page.html',
    styleUrls: ['./bookings.page.scss'],
})
export class BookingsPage implements OnDestroy {
    @ViewChild('menu') menu: MenuComponent;
    @ViewChild('slider') slider: IonSlides;

    type: ClientType;
    activeIndex = 0;

    menuItems: MenuItem[];
    tabs: BookingTab[] = ['CONTACTS', 'WAITING', 'VALID', 'FINISHED'];

    slideOpts = { autoHeight: true, initialSlide: 0 };

    onDestroy$ = new Subject<void>();

    constructor(private route: ActivatedRoute, private store: Store, private actions$: Actions) {
        this.route.params.pipe(take(1)).subscribe((params) => {
            this.type = params.type as ClientType;

            this.menuItems = [
                {
                    name: 'Contacts',
                    icon: 'bookings/contacts-icon',
                    badge: this.store.select(
                        BookingsSelectors.selectBookingsBadge(this.type, 'CONTACTS'),
                    ),
                    selectedColor: this.color,
                },
                {
                    name: 'En attente',
                    icon: 'bookings/waiting-icon',
                    badge: this.store.select(
                        BookingsSelectors.selectBookingsBadge(this.type, 'WAITING'),
                    ),
                    selectedColor: this.color,
                },
                {
                    name: 'Validées',
                    icon: 'bookings/valid-icon',
                    badge: this.store.select(
                        BookingsSelectors.selectBookingsBadge(this.type, 'VALID'),
                    ),
                    selectedColor: this.color,
                },
                {
                    name: 'Terminées',
                    icon: 'bookings/finished-icon',
                    badge: this.store.select(
                        BookingsSelectors.selectBookingsBadge(this.type, 'FINISHED'),
                    ),
                    selectedColor: 'yellow',
                },
            ];

            if (params.tab) {
                setTimeout(() => {
                    this._changeTab(params.tab);
                });
            } else {
                this.store
                    .select(BookingsSelectors.selectBookings(this.type))
                    .pipe(
                        filter((bookings) => !!bookings && !!bookings.length),
                        take(1),
                    )
                    // eslint-disable-next-line rxjs/no-nested-subscribe
                    .subscribe((bookings) => {
                        let tab: BookingTab = 'CONTACTS';

                        for (const booking of bookings) {
                            const seen =
                                this.type === 'client'
                                    ? booking.seenByClient
                                    : booking.seenByPetSitter;
                            if (!seen) {
                                tab = getBookingTabByStatus(booking.status);
                                break;
                            }
                        }

                        setTimeout(() => {
                            this._changeTab(tab);
                        });
                    });
            }
        });

        this.actions$
            .pipe(
                ofType(BookingsActions.updateBookings),
                debounceTime(1000),
                takeUntil(this.onDestroy$),
            )
            .subscribe(() => {
                this._see(this.activeIndex);
            });
    }

    get color(): 'red' | 'lightPurple' {
        return this.type === 'client' ? 'lightPurple' : 'red';
    }

    async ionSlideWillChange(): Promise<void> {
        const active = await this.slider.getActiveIndex();
        this.menu.setActive(active);
        this.activeIndex = active;
        this._see(active);
    }

    private _see(index: number): void {
        const tab = this.tabs[index];

        this.store.dispatch(BookingsActions.seeBookings({ clientType: this.type, tab }));
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private _changeTab(tab: BookingTab): void {
        void this.slider.slideTo(this.tabs.indexOf(tab));
        if (this.activeIndex === 0 && tab === 'CONTACTS') {
            this._see(0);
        }
    }
}
