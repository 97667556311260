import { LabelType, Options } from '@angular-slider/ngx-slider';
import { SliderComponent } from '@angular-slider/ngx-slider/slider.component';
import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
    selector: 'app-forms-value-slider',
    templateUrl: './value-slider.component.html',
    styleUrls: ['./value-slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ValueSliderComponent),
            multi: true,
        },
    ],
})
export class ValueSliderComponent implements OnInit, ControlValueAccessor {
    @ViewChild('slider') slider: SliderComponent;

    @Input() formControlName: string;
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() suffix: string;

    control: FormControl;

    propagateChange: (_: number) => void;
    propagateTouch: () => void;

    value: number;

    sliderOpts: Options;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }

        this.sliderOpts = {
            floor: this.minValue,
            ceil: this.maxValue,
            animate: true,
            hideLimitLabels: true,
            showSelectionBar: true,
            translate: (value: number, _label: LabelType): string => {
                if (!this.suffix) {
                    return value.toString();
                } else {
                    return `${value} ${this.suffix}`;
                }
            },
        };
    }

    detectChanges(): void {
        this.control?.setValue(this.value);
        this.cd.detectChanges();
    }

    registerOnChange(fn: (_: number) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: number): void {
        this.value = value;
        // if (value) {
        //     this.range = { ...value };
        // } else {
        //     this.range = { min: this.minValue, max: this.maxValue };
        // }
        this.control?.markAsTouched();
        // this.control?.setValue(this.value);
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }
}
