import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';

interface AlertOptions {
    title?: string;
    text?: string;
    texts?: string[];
    icon?: string;
    confirmTxt: string;
    skipTxt?: string;
    closeIcon?: boolean;
    color?: 'lightPurple' | 'red';
    [key: string]: unknown;
}

type ModalEvent = 'CONFIRMED' | 'SKIPPED' | 'CLOSED';

@Injectable()
export class ModalService {
    modalContainer: ViewContainerRef;
    ionModals: HTMLIonModalElement[] = [];
    routerOutlet: IonRouterOutlet;

    constructor(
        private modalCtrl: ModalController,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {}

    async openIonModal(options: ModalOptions): Promise<HTMLIonModalElement> {
        options.mode = 'ios';
        if (options.presentingElement !== null && !options.presentingElement) {
            if (this.ionModals.length > 0) {
                options.presentingElement = this.ionModals[this.ionModals.length - 1];
            } else {
                options.presentingElement = this.routerOutlet.nativeEl;
            }
        }

        if (options.presentingElement === null) {
            options.presentingElement = undefined;
        }

        options.swipeToClose = options.swipeToClose || true;
        const modal = await this.modalCtrl.create(options);
        void modal.present();

        this.ionModals.push(modal);

        void modal.onDidDismiss().then(() => {
            this.ionModals.pop();
        });

        return modal;
    }

    openModal<T>(component: Type<T>, componentProps?: { [key: string]: unknown }): T {
        const childComponent = this.componentFactoryResolver.resolveComponentFactory<T>(component);
        const instance = this.modalContainer.createComponent<T>(childComponent).instance;

        for (const prop in componentProps) {
            // eslint-disable-next-line no-prototype-builtins
            if (componentProps.hasOwnProperty(prop)) {
                instance[prop] = componentProps[prop];
            }
        }

        return instance;
    }

    closeModal(): void {
        this.modalContainer.clear();
    }

    dismissIonModal<T = unknown>(data?: T, role?: string): void {
        // const modal = this.ionModals.pop();
        const modal = this.ionModals[this.ionModals.length - 1];
        void modal.dismiss(data, role);
    }

    async alert(options: AlertOptions): Promise<ModalEvent> {
        return new Promise((resolve) => {
            const instance = this.openModal(AlertModalComponent, options);
            instance.autoclose = true;
            instance.onConfirm.subscribe(() => {
                resolve('CONFIRMED');
            });
            instance.onSkip.subscribe(() => {
                resolve('SKIPPED');
            });
            instance.onClose.subscribe(() => {
                resolve('CLOSED');
            });
        });
    }

    async success(options: {
        title?: string;
        text: string;
        icon?: string;
        confirmTxt?: string;
        color?: 'red' | 'lightPurple';
    }): Promise<ModalEvent> {
        options.icon = options.icon || 'assets/images/dog.svg';
        options.confirmTxt = options.confirmTxt || 'OK';
        options.color = options.color || 'lightPurple';

        const { title, text, icon, confirmTxt, color } = options;

        return this.alert({ title, text, icon, confirmTxt, color, closeIcon: false });
    }

    async error(options: {
        title?: string;
        text: string;
        icon?: string;
        confirmTxt?: string;
        color?: 'red' | 'lightPurple';
    }): Promise<ModalEvent> {
        options.icon = options.icon || 'assets/images/dog-tears.svg';
        options.confirmTxt = options.confirmTxt || 'OK';
        options.color = options.color || 'red';

        const { title, text, icon, confirmTxt, color } = options;

        return this.alert({ title, text, icon, confirmTxt, color, closeIcon: false });
    }

    async confirm(options: {
        title?: string;
        text: string;
        icon?: string;
        confirmTxt?: string;
        skipTxt?: string;
        color?: 'red' | 'lightPurple';
    }): Promise<ModalEvent> {
        const { title, text, icon, confirmTxt, skipTxt, color } = options;

        return this.alert({
            title,
            text,
            icon,
            confirmTxt: confirmTxt || 'Oui',
            skipTxt: skipTxt || 'Non',
            closeIcon: false,
            color: color || 'lightPurple',
        });
    }
}
