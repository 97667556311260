import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { HapticService } from 'src/app/core/services/haptic.service';
import { FormConfig } from 'src/app/shared/components/forms/form/form.config';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
})
export class FormComponent implements OnChanges {
    @Input() config: FormConfig;
    @Input() validators: ValidatorFn | ValidatorFn[];
    @Input() model: unknown;
    @Input()
    set modal(value: unknown) {
        this.isModal = convertToBoolProperty(value);
    }
    @Input()
    set embed(value: unknown) {
        this.isEmbed = convertToBoolProperty(value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() onSubmit = new EventEmitter<any>();
    @Output() fakeInputClick = new EventEmitter<string>();
    @Output() onSubmitWithError = new EventEmitter<ValidationErrors>();

    isModal = false;
    isEmbed = false;
    form: FormGroup;

    valueChanges$ = new Subject<unknown>();
    valueChangesSubscription: Subscription;

    constructor(private haptic: HapticService, private fb: FormBuilder) {}

    ngOnChanges(): void {
        this.form = this._createForm();
        if (this.model) {
            this.form.patchValue(this.model);
        }
    }

    private _createForm(): FormGroup {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }

        const controlsConfig: { [key: string]: unknown } = {};
        for (const configField of this.config) {
            let defaultValue = null;
            if (['checkbox-card', 'checkbox'].indexOf(configField.type) !== -1) {
                defaultValue = false;
            } else if (configField.type === 'pictures') {
                defaultValue = [];
            }
            controlsConfig[configField.name] = [defaultValue, configField.validators];
        }

        const form = this.fb.group(controlsConfig, { validators: this.validators });

        this.valueChangesSubscription = form.valueChanges.subscribe((values) =>
            this.valueChanges$.next(values),
        );

        return form;
    }

    get valid(): boolean {
        return this.form.valid;
    }

    submit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.haptic.errorNotification();
            this.onSubmitWithError.emit(this.form.errors);

            return;
        }
        this.onSubmit.emit(this.form.value);
    }
}
