import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AvailabilitiesResponseDto,
    EditPetSitterProfileDto,
    EditServicesDto,
    PetSitterProfileResponseDto,
    PetSitterServicesResponseDto,
    PetSitterSignupDto,
    UpdateAvailabilitiesDto,
} from 'src/app/core/services/dog-vacances-api/pet-sitter/pet-sitter.dtos';
import { ReviewResponseDto } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class PetSitterService {
    private baseUrl = environment.api.baseUrl + '/pet-sitter';

    constructor(private http: HttpClient) {}

    signup(dto: PetSitterSignupDto): Observable<PetSitterProfileResponseDto> {
        return this.http.post<PetSitterProfileResponseDto>(`${this.baseUrl}/signup`, dto);
    }

    editProfile(dto: EditPetSitterProfileDto): Observable<PetSitterProfileResponseDto> {
        return this.http.put<PetSitterProfileResponseDto>(`${this.baseUrl}/profile`, dto);
    }

    getProfile(): Observable<PetSitterProfileResponseDto> {
        return this.http.get<PetSitterProfileResponseDto>(`${this.baseUrl}/profile`);
    }

    getServices(): Observable<PetSitterServicesResponseDto> {
        return this.http.get<PetSitterServicesResponseDto>(`${this.baseUrl}/services`);
    }

    editServices(dto: EditServicesDto): Observable<PetSitterServicesResponseDto> {
        return this.http.put<PetSitterServicesResponseDto>(`${this.baseUrl}/services`, dto);
    }

    getAvailabilities(): Observable<AvailabilitiesResponseDto> {
        return this.http.get<AvailabilitiesResponseDto>(`${this.baseUrl}/availabilities`);
    }

    updateAvailabilities(dto: UpdateAvailabilitiesDto): Observable<AvailabilitiesResponseDto> {
        return this.http.put<AvailabilitiesResponseDto>(`${this.baseUrl}/availabilities`, dto);
    }

    getReviews(): Observable<ReviewResponseDto[]> {
        return this.http.get<ReviewResponseDto[]>(`${this.baseUrl}/reviews`);
    }

    seeReviews(): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/reviews/see`, null);
    }
}
