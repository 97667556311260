import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PetSitterService } from 'src/app/core/services/dog-vacances-api/pet-sitter/pet-sitter.service';
import { AuthActions } from 'src/stores/auth/auth.actions';
import { PetSitterActions } from 'src/stores/pet-sitter/pet-sitter.actions';

@Injectable()
export class PetSitterEffects {
    signup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.signup),
            mergeMap(({ dto }) =>
                this.petSitterService
                    .signup(dto)
                    .pipe(map((profile) => PetSitterActions.signupSuccess({ profile }))),
            ),
        ),
    );

    signupSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.signupSuccess),
            mergeMap(() => of(AuthActions.loadUser())),
        ),
    );

    loadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.loadProfile),
            mergeMap(() =>
                this.petSitterService
                    .getProfile()
                    .pipe(map((profile) => PetSitterActions.loadProfileSuccess({ profile }))),
            ),
        ),
    );

    editProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.editProfile),
            mergeMap(({ dto }) =>
                this.petSitterService
                    .editProfile(dto)
                    .pipe(map((profile) => PetSitterActions.editProfileSuccess({ profile }))),
            ),
        ),
    );

    loadServices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.loadServices),
            mergeMap(() =>
                this.petSitterService
                    .getServices()
                    .pipe(map((services) => PetSitterActions.loadServicesSuccess({ services }))),
            ),
        ),
    );

    editService$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.editServices),
            mergeMap(({ dto }) =>
                this.petSitterService
                    .editServices(dto)
                    .pipe(map((services) => PetSitterActions.editServicesSuccess({ services }))),
            ),
        ),
    );

    loadAvailabilities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.loadAvailabilities),
            mergeMap(() =>
                this.petSitterService
                    .getAvailabilities()
                    .pipe(
                        map((availabilities) =>
                            PetSitterActions.loadAvailabilitiesSuccess({ availabilities }),
                        ),
                    ),
            ),
        ),
    );

    updateAvailabilities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.updateAvailabilities),
            mergeMap(({ dto }) =>
                this.petSitterService
                    .updateAvailabilities(dto)
                    .pipe(
                        map((availabilities) =>
                            PetSitterActions.updateAvailabilitiesSuccess({ availabilities }),
                        ),
                    ),
            ),
        ),
    );

    reloadProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                PetSitterActions.updateAvailabilitiesSuccess,
                PetSitterActions.editServicesSuccess,
            ),
            mergeMap(() => of(PetSitterActions.loadProfile())),
        ),
    );

    loadReviews$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PetSitterActions.loadReviews),
            mergeMap(() =>
                this.petSitterService
                    .getReviews()
                    .pipe(map((reviews) => PetSitterActions.loadReviewsSuccess({ reviews }))),
            ),
        ),
    );

    seeReviews$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PetSitterActions.seeReviews),
                mergeMap(() => this.petSitterService.seeReviews()),
            ),
        { dispatch: false },
    );

    constructor(private actions$: Actions, private petSitterService: PetSitterService) {}
}
