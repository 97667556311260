import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { AnimationController, Animation } from '@ionic/angular';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('button') buttonRef: ElementRef;

    @Input()
    set floating(value: unknown) {
        this.isFloating = convertToBoolProperty(value);
    }

    @Input()
    set full(value: unknown) {
        this.isFull = convertToBoolProperty(value);
    }

    @Input()
    set placeholder(value: unknown) {
        this.isPlaceholder = convertToBoolProperty(value);
    }

    @Input() disabled = false;
    @Input() isLoading = false;
    @Input() color: 'red' | 'lightPurple' | 'transparent' = 'lightPurple';

    @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

    isFloating = false;
    isFull = false;
    isPlaceholder = false;
    listeners: PluginListenerHandle[] = [];
    animation: Animation;

    constructor(private ngZone: NgZone, private animationCtrl: AnimationController) {}

    async ngOnInit(): Promise<void> {
        if (Capacitor.getPlatform() !== 'web') {
            this.listeners.push(
                await Keyboard.addListener('keyboardWillShow', () => {
                    this.ngZone.run(() => {
                        // void this.animation.duration(0).direction('normal').play();
                    });
                }),
            );
            this.listeners.push(
                await Keyboard.addListener('keyboardDidHide', () => {
                    this.ngZone.run(() => {
                        // void this.animation.duration(200).direction('reverse').play();
                    });
                }),
            );
        }
    }

    ngAfterViewInit(): void {
        // this.animation = this.animationCtrl
        //     .create()
        //     .addElement(this.buttonRef.nativeElement)
        //     .fromTo('transform', 'translateY(0)', 'translateY(300%)');
    }

    ngOnDestroy(): void {
        for (const listener of this.listeners) {
            void listener.remove();
        }
    }

    click(): void {
        this.buttonClicked.emit();
    }
}
