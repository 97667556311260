import { createAction, props } from '@ngrx/store';
import {
    AvailabilitiesResponseDto,
    EditPetSitterProfileDto,
    EditServicesDto,
    PetSitterProfileResponseDto,
    PetSitterServicesResponseDto,
    PetSitterSignupDto,
    UpdateAvailabilitiesDto,
} from 'src/app/core/services/dog-vacances-api/pet-sitter/pet-sitter.dtos';
import { ReviewResponseDto } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';

const domain = '[PET-SITTER]';

const clear = createAction(`${domain} Clear`);

/**
 * Signup
 */
const signup = createAction(`${domain} Signup`, props<{ dto: PetSitterSignupDto }>());
const signupSuccess = createAction(
    `${domain} Signup success`,
    props<{ profile: PetSitterProfileResponseDto }>(),
);
const SIGNUP_ACTIONS = { signup, signupSuccess };

/**
 * Load profile
 */
const loadProfile = createAction(`${domain} Load profile`);
const loadProfileSuccess = createAction(
    `${domain} Load profile success`,
    props<{ profile: PetSitterProfileResponseDto }>(),
);
const LOAD_PROFILE_ACTIONS = { loadProfile, loadProfileSuccess };

/**
 * Edit profile
 */
const editProfile = createAction(
    `${domain} Edit profile`,
    props<{ dto: EditPetSitterProfileDto }>(),
);
const editProfileSuccess = createAction(
    `${domain} Edit Profile success`,
    props<{ profile: PetSitterProfileResponseDto }>(),
);
const EDIT_PROFILE_ACTIONS = { editProfile, editProfileSuccess };

/**
 * Load services
 */
const loadServices = createAction(`${domain} Load services`);
const loadServicesSuccess = createAction(
    `${domain} Load services success`,
    props<{ services: PetSitterServicesResponseDto }>(),
);
const LOAD_SERVICES_ACTIONS = { loadServices, loadServicesSuccess };

/**
 * Edit service
 */
const editServices = createAction(`${domain} Edit services`, props<{ dto: EditServicesDto }>());
const editServicesSuccess = createAction(
    `${domain} Edit services success`,
    props<{ services: PetSitterServicesResponseDto }>(),
);
const EDIT_SERVICES_ACTIONS = { editServices, editServicesSuccess };

/**
 * Load availabilities
 */
const loadAvailabilities = createAction(`${domain} Load availabilities`);
const loadAvailabilitiesSuccess = createAction(
    `${domain} Load availabilities success`,
    props<{ availabilities: AvailabilitiesResponseDto }>(),
);
const LOAD_AVAILABILITIES_ACTIONS = { loadAvailabilities, loadAvailabilitiesSuccess };

/**
 * Update availabilities
 */
const updateAvailabilities = createAction(
    `${domain} Update availabilities`,
    props<{ dto: UpdateAvailabilitiesDto }>(),
);
const updateAvailabilitiesSuccess = createAction(
    `${domain} Update availabilities success`,
    props<{ availabilities: AvailabilitiesResponseDto }>(),
);
const UPDATE_AVAILABILITIES_ACTIONS = { updateAvailabilities, updateAvailabilitiesSuccess };

/**
 * Reviews
 */
const loadReviews = createAction(`${domain} Load reviews`);
const loadReviewsSuccess = createAction(
    `${domain} Load reviews success`,
    props<{ reviews: ReviewResponseDto[] }>(),
);
const seeReviews = createAction(`${domain} See reviews`);
const REVIEWS_ACTIONS = { loadReviews, loadReviewsSuccess, seeReviews };

export const PetSitterActions = {
    clear,
    ...SIGNUP_ACTIONS,
    ...LOAD_PROFILE_ACTIONS,
    ...EDIT_PROFILE_ACTIONS,
    ...LOAD_SERVICES_ACTIONS,
    ...EDIT_SERVICES_ACTIONS,
    ...LOAD_AVAILABILITIES_ACTIONS,
    ...UPDATE_AVAILABILITIES_ACTIONS,
    ...REVIEWS_ACTIONS,
};
