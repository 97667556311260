import { Component, Input } from '@angular/core';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
    @Input()
    set subtitle(value: unknown) {
        this.isSubtitle = convertToBoolProperty(value);
    }

    @Input()
    set uppercase(value: unknown) {
        this.isUppercase = convertToBoolProperty(value);
    }

    @Input()
    set noMarginTop(value: unknown) {
        this.hasMarginTop = !convertToBoolProperty(value);
    }

    @Input()
    set marginBottom(value: unknown) {
        this.hasMarginBottom = convertToBoolProperty(value);
    }

    @Input() color: 'lightPurple' | 'red' | 'black' = 'black';

    isSubtitle = false;
    hasMarginTop = true;
    hasMarginBottom = false;
    isUppercase = false;
}
