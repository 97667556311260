import { LabelType, Options } from '@angular-slider/ngx-slider';
import { SliderComponent } from '@angular-slider/ngx-slider/slider.component';
import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NumberRangeDto } from 'src/app/shared/dtos/number-range.dto';

@Component({
    selector: 'app-forms-range',
    templateUrl: './range.component.html',
    styleUrls: ['./range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RangeComponent),
            multi: true,
        },
    ],
})
export class RangeComponent implements OnInit, ControlValueAccessor {
    @ViewChild('slider') slider: SliderComponent;

    @Input() formControlName: string;
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() currency: boolean;

    control: FormControl;

    propagateChange: (_: NumberRangeDto) => void;
    propagateTouch: () => void;

    range: NumberRangeDto;

    value = 40;

    sliderOpts: Options;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }

        this.sliderOpts = {
            floor: this.minValue,
            ceil: this.maxValue,
            animate: true,
            hideLimitLabels: true,
            translate: (value: number, label: LabelType): string => {
                if (!this.currency) {
                    return value.toString();
                }
                if (label === LabelType.Low) {
                    return `${value} €`;
                } else if (label === LabelType.High) {
                    return `${value} €`;
                }
            },
        };
    }

    detectChanges(): void {
        this.cd.detectChanges();
    }

    registerOnChange(fn: (_: NumberRangeDto) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: NumberRangeDto): void {
        if (value) {
            this.range = { ...value };
        } else {
            this.range = { min: this.minValue, max: this.maxValue };
        }
        this.control?.markAsTouched();
        this.control?.setValue(this.range);
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }
}
