import { PipeTransform, Pipe } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GeolocationService, Location } from 'src/app/core/services/geolocation.service';
import { AddressDto } from 'src/app/shared/dtos/address.dto';
import { LocationSelectors } from 'src/stores/location/location.selectors';

@Pipe({ name: 'distance' })
export class DistancePipe implements PipeTransform {
    constructor(private store: Store, private geolocationService: GeolocationService) {}

    transform(address: AddressDto, location: Location = null): string {
        let result: string = null;
        if (location) {
            result = this.geolocationService.getDistance(address.location, location);
        } else {
            this.store
                .select(LocationSelectors.selectCoords)
                .pipe(take(1))
                .subscribe((location) => {
                    result = this.geolocationService.getDistance(address.location, location);
                });
        }

        return result;
    }
}
