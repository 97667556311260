/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(private navCtrl: NavController, private authService: AuthService) {}

    // tslint:disable-next-line: no-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 404:
                            break;
                        case 401:
                            void this._redirect(['/home']);
                            void this.authService.logout();
                            break;
                        case 403:
                            break;
                        case 500:
                            break;
                        case 400:
                            break;
                        default:
                            break;
                    }
                }

                return throwError(error);
            }),
        );
    }

    private _redirect(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.navCtrl.navigateRoot(commands, extras);
    }
}
