import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { AppState } from 'src/stores';
import { SettingsState } from 'src/stores/settings/settings.state';

const selectSettings = createFeatureSelector<AppState, SettingsState>('settings');

const selectShowNotificationsInstructions = createSelector(
    selectSettings,
    ({ showNotificationsInstructions }) => showNotificationsInstructions,
);

export const SettingsSelectors = {
    selectShowNotificationsInstructions,
};
