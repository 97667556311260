import { Component, Input } from '@angular/core';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
    @Input()
    set noArrow(value: unknown) {
        this.hasArrow = !convertToBoolProperty(value);
    }

    @Input() badge?: string | number;
    @Input() color: 'red' | 'yellow' | 'black' | 'lightPurple' = 'black';

    hasArrow = true;
}
