import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    AddEditPetDto,
    PetResponseDto,
} from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class PetsService {
    private baseUrl = environment.api.baseUrl + '/pets';

    constructor(private http: HttpClient) {}

    getPets(): Observable<PetResponseDto[]> {
        return this.http.get<PetResponseDto[]>(`${this.baseUrl}/my-pets`);
    }

    addPet(dto: AddEditPetDto): Observable<PetResponseDto> {
        return this.http.post<PetResponseDto>(`${this.baseUrl}`, dto);
    }

    editPet(petId: string, dto: AddEditPetDto): Observable<PetResponseDto> {
        return this.http.put<PetResponseDto>(`${this.baseUrl}/${petId}`, dto);
    }

    removePet(petId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${petId}`);
    }
}
