import { Component, Input } from '@angular/core';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-link-card',
    templateUrl: './link-card.component.html',
    styleUrls: ['./link-card.component.scss'],
})
export class LinkCardComponent {
    @Input() arrowIcon = 'right-arrow-icon';
    @Input() arrowColor: 'yellow' | 'lightPurple' | 'red' | 'darkGray' = 'yellow';
    @Input() arrowText: string;
    @Input() icon?: string;
    @Input() iconColor: 'yellow' | 'red' | 'darkGray' = 'yellow';
    @Input() image?: string;
    @Input() imagePlaceholder?: string;
    @Input()
    set compact(value: unknown) {
        this.isCompact = convertToBoolProperty(value);
    }
    @Input()
    set noArrow(value: unknown) {
        this.hasArrow = !convertToBoolProperty(value);
    }
    @Input()
    set skeleton(value: unknown) {
        this.isSkeleton = convertToBoolProperty(value);
    }

    isCompact = false;
    hasArrow = true;
    isSkeleton = false;
}
