import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, map, take } from 'rxjs/operators';
import { AutocompleteDto } from 'src/app/core/services/google-place/google-place.dtos';
import { GooglePlacesService } from 'src/app/core/services/google-place/google-place.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { AddressDto } from 'src/app/shared/dtos/address.dto';
import { LocationSelectors } from 'src/stores/location/location.selectors';
import $ from 'jquery';
import { HapticService } from 'src/app/core/services/haptic.service';

@Component({
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit, OnDestroy {
    @ViewChild('input') input: ElementRef;

    @Input() title = 'Lieu';
    @Input() allowCurrentLocation: boolean;

    onDestroy$ = new Subject<void>();

    locations: AutocompleteDto[] = [];
    recentLocations: AutocompleteDto[] = [];

    form = this.fb.group({
        query: [''],
    });

    constructor(
        private modalService: ModalService,
        private fb: FormBuilder,
        private googlePlacesService: GooglePlacesService,
        private storage: LocalStorageService,
        private store: Store,
        private loader: LoaderService,
        private haptic: HapticService,
    ) {
        this.form.valueChanges
            .pipe(
                map(({ query }: { query: string }) => query),
                filter((res: string) => res.length === 0 || res.length >= 3),
                debounceTime(200),
                distinctUntilChanged(),
                takeUntil(this.onDestroy$),
            )
            .subscribe((searchQuery) => {
                if (searchQuery.length === 0) {
                    this.locations = [];
                } else {
                    void this.googlePlacesService.autocomplete(searchQuery).then((locations) => {
                        this.locations = locations;
                    });
                }
            });
    }

    async ngOnInit(): Promise<void> {
        const recentLocations = await this.storage.get<AutocompleteDto[]>(
            'RECENT_LOCATION_SEARCHES',
        );
        if (recentLocations) {
            this.recentLocations = recentLocations;
        }
    }

    ionViewDidEnter(): void {
        setTimeout(() => {
            $(this.input.nativeElement).trigger('focus');
        });
    }

    clearSearch(): void {
        this.form.patchValue({ query: '' });
    }

    async select(address: AutocompleteDto): Promise<void> {
        this.haptic.mediumImpact();
        await this._saveInHistory(address);

        await this.loader.present();
        const { location, city, country } = await this.googlePlacesService.getPlaceDetails(
            address.placeId,
        );
        await this.loader.dismiss();

        this.modalService.dismissIonModal<AddressDto>({
            description: address.description,
            location,
            city,
            country,
        });
    }

    currentLocation(): void {
        this.store
            .select(LocationSelectors.selectCoords)
            .pipe(take(1))
            .subscribe((location) => {
                this.modalService.dismissIonModal<AddressDto>({
                    description: 'Ma position actuelle',
                    location,
                });
            });
    }

    private async _saveInHistory(address: AutocompleteDto): Promise<void> {
        let recentLocations =
            (await this.storage.get<AutocompleteDto[]>('RECENT_LOCATION_SEARCHES')) || [];

        recentLocations = recentLocations.filter((item) => item.placeId !== address.placeId);

        recentLocations.unshift(address);
        if (recentLocations.length > 10) {
            recentLocations.pop();
        }

        await this.storage.set<AutocompleteDto[]>('RECENT_LOCATION_SEARCHES', recentLocations);
    }

    close(): void {
        this.modalService.dismissIonModal();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
