import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CancelPetSittingDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-requests.dtos';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { CancelForm } from 'src/app/features/user/forms/cancel-form';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';

@Component({
    templateUrl: './cancel-modal.component.html',
    providers: [CancelForm],
})
export class CancelModalComponent implements OnDestroy {
    @Input() booking: BookingResponseDto;
    @Input() type: ClientType;

    @ViewChild('modal') modal: AlertModalComponent;

    formConfig = this.form.getConfig();
    isLoading = false;

    onDestroy$ = new Subject<void>();

    constructor(private form: CancelForm, private store: Store, private actions$: Actions) {
        this.actions$
            .pipe(
                ofType(
                    BookingsActions.cancelPetSittingSuccess,
                    BookingsActions.cancelPetSittingError,
                ),
                takeUntil(this.onDestroy$),
            )
            .subscribe(() => {
                void this.modal.close();
            });
    }

    submit(dto: CancelPetSittingDto): void {
        this.isLoading = true;
        this.store.dispatch(
            BookingsActions.cancelPetSitting({ clientType: this.type, booking: this.booking, dto }),
        );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
