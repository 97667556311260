import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import {
    AppMessagePayload,
    SseEventType,
} from 'src/app/core/services/dog-vacances-api/sse/sse.dtos';
import { SseActions } from 'src/stores/sse/sse.actions';

type MappingActionCreator = ActionCreator<
    string,
    (props: { payload: AppMessagePayload }) => { payload: AppMessagePayload } & TypedAction<string>
>;

interface EventMapping {
    type: SseEventType;
    action: MappingActionCreator;
}

const mapping: EventMapping[] = [
    { type: 'NEW_MESSAGE', action: SseActions.newMessage },
    { type: 'NEW_CONTACT_REQUEST', action: SseActions.newContactRequest },
    { type: 'CONTACT_REQUEST_APPROVED', action: SseActions.contactRequestApproved },
    { type: 'PET_SITTING_APPROVED', action: SseActions.petSittingApproved },
    { type: 'PET_SITTING_CANCELED', action: SseActions.petSittingCanceled },
    { type: 'PET_SITTING_EXPIRED', action: SseActions.petSittingExpired },
    { type: 'PET_SITTING_FINISHED', action: SseActions.petSittingFinished },
    { type: 'PET_SITTING_RATED', action: SseActions.petSittingRated },
    { type: 'NOTIFICATION', action: SseActions.notification },
];

export const getActionFromEventType = (type: SseEventType): MappingActionCreator => {
    return mapping.find((item) => item.type === type).action;
};
