import {
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { PictureService } from 'src/app/core/services/dog-vacances-api/picture/picture.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { PictureDto } from 'src/app/shared/dtos/picture.dto';
import { Utils } from 'src/app/shared/utils';
import { Camera, CameraResultType } from '@capacitor/camera';

@Component({
    selector: 'app-forms-picture',
    templateUrl: './picture.component.html',
    styleUrls: ['./picture.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PictureComponent),
            multi: true,
        },
    ],
})
export class PictureComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() placeholder: string;
    @Input() icon = 'forms/picture-icon';
    @Input() folder: string;
    @Input() allowEditing = false;

    isLoading = false;

    control: FormControl;

    onDestroy$ = new Subject<void>();

    picture: PictureDto;

    propagateChange: (_: PictureDto) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private pictureService: PictureService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: PictureDto) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: PictureDto): void {
        if (value) {
            this.picture = value;
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    async uploadPicture(): Promise<void> {
        if (this.isLoading) {
            return;
        }
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: this.allowEditing,
            resultType: CameraResultType.DataUrl,
            presentationStyle: 'popover',
            width: 1000,
            preserveAspectRatio: true,
        });

        const file = Utils.dataURItoBlob(image.dataUrl);

        this.isLoading = true;
        this.pictureService.upload({ folder: this.folder }, file).subscribe(
            (picture) => {
                this.isLoading = false;
                this.picture = picture;
                this.propagateChange(picture);
            },
            () => (this.isLoading = false),
        );
    }

    async removePicture(): Promise<void> {
        const event = await this.modalService.confirm({
            title: 'Confirmation',
            text: `Confirmez-vous la suppression de l'image&nbsp;?`,
            icon: 'assets/images/dog-tears.svg',
            color: 'red',
        });
        if (event === 'CONFIRMED') {
            this.picture = null;
            this.propagateChange(null);
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
