import { Injectable } from '@angular/core';
import { DeviceService } from 'src/app/core/services/dog-vacances-api/device/device.service';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { DeviceDto } from 'src/app/core/services/dog-vacances-api/device/device.dtos';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { SettingsActions } from 'src/stores/settings/settings.actions';
import { take } from 'rxjs/operators';
import { ModalService } from 'src/app/core/services/modal.service';
import { SettingsSelectors } from 'src/stores/settings/settings.selectors';

@Injectable()
export class PushNotificationService {
    notifications = new Subject<PushNotificationSchema>();

    constructor(
        private deviceService: DeviceService,
        private store: Store,
        private modalService: ModalService,
    ) {
        void this._init();
    }

    private async _init(): Promise<void> {
        const { platform } = await Device.getInfo();

        if (!['ios', 'android'].includes(platform)) {
            return;
        }

        void PushNotifications.addListener('registration', ({ value }) => {
            console.log('Push notification registered with token', value);
            void this._registerDevice(value);
        });
        void PushNotifications.addListener('registrationError', (error) => {
            console.log('Push notification registration error', error);
        });
        void PushNotifications.addListener('pushNotificationReceived', (notification) => {
            this.notifications.next(notification);
        });
    }

    async register(): Promise<void> {
        const { platform } = await Device.getInfo();

        if (!['ios', 'android'].includes(platform)) {
            return;
        }

        this.store
            .select(SettingsSelectors.selectShowNotificationsInstructions)
            .pipe(take(1))
            // eslint-disable-next-line @typescript-eslint/no-misused-promises, rxjs/no-async-subscribe
            .subscribe(async (showNotificationsInstructions) => {
                if (platform === 'android' || !showNotificationsInstructions) {
                    const { receive } = await PushNotifications.requestPermissions();
                    if (receive === 'granted') {
                        void PushNotifications.register();
                    }
                } else {
                    this.store.dispatch(
                        SettingsActions.setShowNotificationsInstructions({
                            showNotificationsInstructions: false,
                        }),
                    );
                    void this._showNotificationsInstructionsModal();
                }
            });
    }

    async unregister(): Promise<void> {
        const { uuid } = await Device.getId();
        const { platform } = await Device.getInfo();

        if (!['ios', 'android'].includes(platform)) {
            return;
        }

        this.deviceService
            .unregister({ uuid, platform: platform as 'ios' | 'android' })
            .subscribe();
    }

    private async _showNotificationsInstructionsModal(): Promise<void> {
        await this.modalService.alert({
            title: 'Notifications',
            text: `Activez les notifications pour être averti en temps réel de la mise à jour des demandes de Pet-Sitting`,
            icon: 'assets/images/dog.svg',
            confirmTxt: 'Suivant',
            color: 'lightPurple',
            autoclose: true,
            closeIcon: false,
        });
        void this.register();
    }

    private async _getDeviceDto(): Promise<DeviceDto> {
        const { uuid } = await Device.getId();
        const { name, model, platform, osVersion, manufacturer } = await Device.getInfo();

        return {
            uuid,
            name,
            model,
            platform: platform as 'ios' | 'android',
            osVersion,
            manufacturer,
        };
    }

    private async _registerDevice(pushToken?: string): Promise<void> {
        const device = await this._getDeviceDto();
        if (pushToken) {
            device.pushToken = pushToken;
        }
        this.deviceService.register(device).subscribe();
    }
}
