import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.authService.getIdToken$().pipe(
            take(1),
            switchMap((token) => {
                if (token) {
                    req = req.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + token,
                        },
                    });
                }

                return next.handle(req);
            }),
        );
    }
}
