import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { BookingsService } from 'src/app/core/services/dog-vacances-api/bookings/bookings.service';
import { PetsService } from 'src/app/core/services/dog-vacances-api/pets/pets.service';
import { ClientActions } from 'src/stores/client/client.actions';

@Injectable()
export class ClientsEffects {
    loadPets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.loadPets),
            mergeMap(() =>
                this.petsService
                    .getPets()
                    .pipe(map((pets) => ClientActions.loadPetsSuccess({ pets }))),
            ),
        ),
    );

    addPet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.addPet),
            mergeMap(({ dto }) =>
                this.petsService
                    .addPet(dto)
                    .pipe(map((pet) => ClientActions.addPetSuccess({ pet }))),
            ),
        ),
    );

    editPet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.editPet),
            mergeMap(({ id, dto }) =>
                this.petsService
                    .editPet(id, dto)
                    .pipe(map((pet) => ClientActions.editPetSuccess({ pet }))),
            ),
        ),
    );

    removePet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientActions.removePet),
            mergeMap(({ id }) =>
                this.petsService
                    .removePet(id)
                    .pipe(map((pet) => ClientActions.removePetSuccess({ id }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private petsService: PetsService,
        private bookingsService: BookingsService,
    ) {}
}
