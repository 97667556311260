import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { BookingTab } from 'src/app/features/user/guards/booking-tab.guard';
import { MessagingTab } from 'src/app/features/user/guards/messaging-tab.guard';
import { getStatusesByTabAndClientType } from 'src/app/features/user/pages/bookings';
import {
    getMessagingStatusesByTabAndClientType,
    getMessagingTabByStatus,
} from 'src/app/features/user/pages/messaging';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { AppState } from 'src/stores';
import { BookingsState } from 'src/stores/bookings/bookings.state';
import { RouterSelectors } from 'src/stores/router/router.selectors';

const selectBookingsState = createFeatureSelector<AppState, BookingsState>('bookings');

/**
 * Bookings
 */

const selectBookings = (
    clientType: ClientType,
    tab?: BookingTab,
): MemoizedSelector<AppState, BookingResponseDto[]> => {
    return createSelector(selectBookingsState, ({ clientBookings, petSitterBookings }) => {
        let bookings = clientType === 'client' ? clientBookings : petSitterBookings;

        if (tab) {
            const statuses = getStatusesByTabAndClientType(clientType, tab);
            bookings = bookings && bookings.filter(({ status }) => statuses.includes(status));
        }

        return bookings;
    });
};

const selectBookingsBadge = (
    clientType: ClientType,
    tab?: BookingTab,
): MemoizedSelector<AppState, number> => {
    return createSelector(selectBookings(clientType, tab), (bookings) => {
        return (
            bookings &&
            bookings.reduce((acc, { seenByClient, seenByPetSitter }) => {
                const seen = clientType === 'client' ? seenByClient : seenByPetSitter;

                return acc + (seen ? 0 : 1);
            }, 0)
        );
    });
};

const selectBooking = (clientType: ClientType): MemoizedSelector<AppState, BookingResponseDto> => {
    return createSelector(
        selectBookings(clientType),
        RouterSelectors.selectRouteParam('id'),
        (bookings, id) => {
            return bookings ? bookings.find((booking) => booking.id === id) : null;
        },
    );
};

const BOOKINGS_SELECTORS = { selectBookings, selectBookingsBadge, selectBooking };

/**
 * Messaging
 */

const selectDiscussions = (
    clientType: ClientType,
    tab: MessagingTab,
): MemoizedSelector<AppState, BookingResponseDto[]> => {
    return createSelector(selectBookings(clientType), (bookings) => {
        const statuses = getMessagingStatusesByTabAndClientType(clientType, tab);

        return bookings && bookings.filter(({ status }) => statuses.includes(status));
    });
};

const selectLastUnseenDiscussion = createSelector(
    selectBookingsState,
    ({ clientBookings, petSitterBookings }) => {
        const bookings = [
            ...(clientBookings ? clientBookings : []),
            ...(petSitterBookings ? petSitterBookings : []),
        ];

        bookings.sort((a, b) => b.lastUpdate - a.lastUpdate);

        for (const booking of bookings) {
            for (const message of booking.messages) {
                if (!getMessagingTabByStatus(booking.status)) {
                    continue;
                }

                if (!message.seen) {
                    return booking;
                }
            }
        }

        return null;
    },
);

const selectMessagingBadge = (clientType: ClientType): MemoizedSelector<AppState, number> => {
    return createSelector(selectDiscussions(clientType, 'IN_PROGRESS'), (bookings) => {
        return (
            bookings &&
            bookings.reduce((acc, current) => {
                const unseen = current.messages.reduce((acc, current) => {
                    return acc + (current.seen ? 0 : 1);
                }, 0);

                return acc + unseen > 0 ? 1 : 0;
            }, 0)
        );
    });
};

const MESSAGING_SELECTORS = { selectDiscussions, selectMessagingBadge, selectLastUnseenDiscussion };

export const BookingsSelectors = {
    ...BOOKINGS_SELECTORS,
    ...MESSAGING_SELECTORS,
};
