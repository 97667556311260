import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { LoginDto } from 'src/app/core/services/auth/auth.dtos';
import { AuthActions } from 'src/stores/auth/auth.actions';
import firebase from 'firebase';
import { PushNotificationService } from 'src/app/core/services/push-notifications.service';
import { PlatformSelectors } from 'src/stores/platform/platform.selectors';

@Injectable()
export class AuthService {
    constructor(
        private ngFireAuth: AngularFireAuth,
        private store: Store,
        private pushNotificationService: PushNotificationService,
    ) {}

    authStateListener(): void {
        this.ngFireAuth.authState.pipe(filter((user) => !user)).subscribe(() => {
            this.store.dispatch(AuthActions.logoutSuccess());
            void this.pushNotificationService.unregister();
        });

        combineLatest([
            this.ngFireAuth.authState.pipe(filter((user) => !!user)),
            this.store.pipe(PlatformSelectors.selectResume),
        ]).subscribe(() => {
            this.store.dispatch(AuthActions.loadUser());
            void this.pushNotificationService.register();
        });
    }

    async login(dto: LoginDto): Promise<void> {
        const { email, password } = dto;

        await this.ngFireAuth.signInWithEmailAndPassword(email, password);
    }

    getIdToken$(): Observable<string> {
        return this.ngFireAuth.idToken;
    }

    logout(): Promise<void> {
        return this.ngFireAuth.signOut();
    }

    async signInWithCustomToken(token: string): Promise<void> {
        await this.ngFireAuth.signInWithCustomToken(token);
    }

    changePassword(currentPassword: string, newPassword: string): Observable<void> {
        return this.ngFireAuth.user.pipe(
            take(1),
            switchMap((user) => {
                const credential = firebase.auth.EmailAuthProvider.credential(
                    user.email,
                    currentPassword,
                );

                return from(user.reauthenticateWithCredential(credential));
            }),
            switchMap(({ user }) => {
                return from(user.updatePassword(newPassword));
            }),
        );
    }

    resetPassword(email: string): Promise<void> {
        return this.ngFireAuth.sendPasswordResetEmail(email);
    }
}
