import { Component, Input } from '@angular/core';
import { PredefinedColors } from '@ionic/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
    @Input() color: PredefinedColors = 'danger';
}
