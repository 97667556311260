import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/stores';
import { PlatformState } from 'src/stores/platform/platform.state';

const selectPlatform = createFeatureSelector<AppState, PlatformState>('platform');

const selectIsActive = createSelector(selectPlatform, ({ isActive }) => isActive);
const selectResume = pipe(
    select(selectIsActive),
    filter((isActive) => isActive),
);

export const PlatformSelectors = {
    selectIsActive,
    selectResume,
};
