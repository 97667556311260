import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
    BookingResponseDto,
    ChatMessageResponseDto,
} from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { ModalService } from 'src/app/core/services/modal.service';
import { ClientType } from 'src/app/shared/guards/client-type.guard';
import { BookingsActions } from 'src/stores/bookings/bookings.actions';
import $ from 'jquery';
import { FormBuilder, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { IonContent, NavController } from '@ionic/angular';
import { BookingsSelectors } from 'src/stores/bookings/bookings.selectors';

@Component({
    templateUrl: './chat.page.html',
    styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnDestroy {
    @ViewChild('buttonRef') buttonRef: ElementRef;
    @ViewChild(IonContent) content: IonContent;

    type: ClientType;
    buttonSticked = false;
    scrollOffset: number;
    isLoading = false;
    messageIsLoading = false;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    form = this.fb.group({ message: [null, Validators.required] });

    booking: Observable<BookingResponseDto>;
    messages: Observable<Array<ChatMessageResponseDto & { title?: string }>>;

    messagesSeen = false;

    onDestroy$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private actions$: Actions,
        private modalService: ModalService,
        private navCtrl: NavController,
        private fb: FormBuilder,
    ) {
        this.booking = this.route.params.pipe(
            switchMap((params) => {
                this.type = params.type as ClientType;

                return this.store.select(BookingsSelectors.selectBooking(this.type));
            }),
            filter((booking) => !!booking),
            tap((booking) => {
                if (!this.messagesSeen) {
                    this.store.dispatch(
                        BookingsActions.seeMessages({ clientType: this.type, booking }),
                    );
                    this.messagesSeen = true;
                }
            }),
        );

        this.messages = this.booking.pipe(
            filter((booking) => !!booking),
            map(({ messages }) => messages as Array<ChatMessageResponseDto & { title?: string }>),
            map((messages) => {
                let previous: dayjs.Dayjs = null;

                return messages.map((item) => {
                    const date = dayjs(item.date);
                    if (!previous || !previous.isSame(date, 'day')) {
                        const title = date.format('dddd D MMMM YYYY');
                        item = {
                            ...item,
                            title: title.slice(0, 1).toUpperCase() + title.slice(1),
                        };
                        previous = date;
                    }

                    return item;
                });
            }),
        );

        this.actions$
            .pipe(ofType(BookingsActions.approvePetSittingSuccess), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.isLoading = false;
                void this.navCtrl.navigateForward([
                    '/user',
                    'bookings',
                    this.type,
                    this.type === 'client' ? 'WAITING' : 'VALID',
                ]);
            });

        this.actions$
            .pipe(ofType(BookingsActions.approvePetSittingError), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.isLoading = false;
            });

        this.actions$
            .pipe(ofType(BookingsActions.createChatMessageSuccess), takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.messageIsLoading = false;
                this.form.reset();
                setTimeout(() => {
                    void this.content.scrollToBottom(400);
                });
            });

        this.actions$
            .pipe(
                ofType(BookingsActions.newMessage),
                withLatestFrom(this.booking),
                filter(([{ payload }, booking]) => payload.bookingId === booking.id),
                takeUntil(this.onDestroy$),
            )
            .subscribe(([, booking]) => {
                this.store.dispatch(
                    BookingsActions.seeMessages({ clientType: this.type, booking }),
                );
                setTimeout(() => {
                    void this.content.scrollToBottom(400);
                });
            });
    }

    get color(): 'red' | 'lightPurple' {
        return this.type === 'client' ? 'lightPurple' : 'red';
    }

    ionScroll(e: Event): void {
        if (e instanceof CustomEvent) {
            const chatHeaderHeight = $('.Chat-header').outerHeight(true);
            const margin = $('.Chat').outerHeight(true) - $('.Chat').innerHeight();
            this.scrollOffset = chatHeaderHeight + margin;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const scrollTop = e.detail.scrollTop;
            this.buttonSticked = scrollTop >= this.scrollOffset;
        }
    }

    async approvePetSitting(booking: BookingResponseDto): Promise<void> {
        let text: string;
        if (this.type === 'client') {
            text = `Confirmez-vous votre demande de Pet-Sitting avec <span class="text-light-purple">${booking.petSitter.displayName}</span>&nbsp;?`;
        } else {
            text = `Acceptez vous le Pet-Sitting avec <span class="text-red">${booking.client.displayName}</span>&nbsp;?`;
        }
        const event = await this.modalService.confirm({
            title: 'Confirmation',
            text,
            icon: 'assets/images/dog.svg',
            color: this.color,
        });
        if (event === 'CONFIRMED') {
            this.isLoading = true;
            this.store.dispatch(
                BookingsActions.approvePetSitting({ clientType: this.type, booking }),
            );
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    submit(booking: BookingResponseDto): void {
        if (this.messageIsLoading) {
            return;
        }
        const message = this.form.get('message').value as string;
        if (!message) {
            return;
        }
        this.messageIsLoading = true;
        this.store.dispatch(
            BookingsActions.createChatMessage({ clientType: this.type, booking, dto: { message } }),
        );
    }
}
