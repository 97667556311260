import {
    Component,
    EventEmitter,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'src/app/core/services/modal.service';
import { SelectModalComponent } from 'src/app/shared/components/forms/select/select-modal/select-modal.component';
import { SelectOption } from 'src/app/shared/components/forms/select/select.config';

@Component({
    selector: 'app-forms-select',
    templateUrl: './select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() modalTitle: string;
    @Input() modalColor: 'lightPurple' | 'red' = 'lightPurple';
    @Input() placeholder: string;
    @Input() formControlName: string;
    @Input() options: SelectOption[];
    @Output() touched = new EventEmitter<void>();

    control: FormControl;
    selected: SelectOption;

    onDestroy$ = new Subject<void>();

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
        this.touched.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.propagateTouch());
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: unknown): void {
        // this.control?.setValue(value, { emitEvent: false });
        if (value) {
            this.selected = this.options.find((item) => item.value === value);
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    async openModal(): Promise<void> {
        const instance = await this.modalService.openIonModal({
            component: SelectModalComponent,
            componentProps: {
                options: this.options,
                title: this.modalTitle,
                color: this.modalColor,
            },
        });
        const { data } = await instance.onWillDismiss<SelectOption>();
        if (data) {
            this.selected = data;
            this.propagateChange(data ? data.value : null);
        }
        this.control.markAsTouched();
    }
}
