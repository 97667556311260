import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ModalService } from 'src/app/core/services/modal.service';
import { SplashScreenComponent } from 'src/app/shared/components/splash-screen/splash-screen.component';
import { PlatformSelectors } from 'src/stores/platform/platform.selectors';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PushNotificationService } from 'src/app/core/services/push-notifications.service';
import { GeolocationService } from 'src/app/core/services/geolocation.service';
import { LocationSelectors } from 'src/stores/location/location.selectors';
import { LocationActions } from 'src/stores/location/location.actions';
import { App } from '@capacitor/app';
import { PlatformActions } from 'src/stores/platform/platform.actions';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer: ViewContainerRef;
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
    @ViewChild(SplashScreenComponent) splashScreen: SplashScreenComponent;

    onDestroy$ = new Subject<void>();

    constructor(
        private modalService: ModalService,
        private store: Store,
        private platform: Platform,
        private iconReg: SvgIconRegistryService,
        private authService: AuthService,
        private pushNotificationsService: PushNotificationService,
    ) {}

    ngOnInit(): void {
        this.authService.authStateListener();
        this._preloadSvgIcons();
        if (this.platform.is('capacitor') && this.platform.is('ios')) {
            void StatusBar.setStyle({ style: Style.Dark });
            void StatusBar.setOverlaysWebView({ overlay: false });
        }

        void App.addListener('appStateChange', (state) => {
            if (state.isActive) {
                this.store.dispatch(PlatformActions.resume());
            } else {
                this.store.dispatch(PlatformActions.pause());
            }
        });

        this.store
            .pipe(
                PlatformSelectors.selectResume,
                withLatestFrom(this.store.select(LocationSelectors.selectStatus)),
            )
            .subscribe(([_, status]) => {
                if (status !== null && status !== 'DEBUG') {
                    this.store.dispatch(LocationActions.update({ openSettingsIfDenied: false }));
                }
            });

        this.pushNotificationsService.notifications.subscribe((notification) => {
            console.log('Notification received', notification);
        });
    }

    ngAfterViewInit(): void {
        this.splashScreen.hideSplashScreen();
        this.modalService.modalContainer = this.modalContainer;
        this.modalService.routerOutlet = this.routerOutlet;
    }

    private _preloadSvgIcons(): void {
        /**
         * Header
         */
        this.iconReg
            .loadSvg('assets/images/header/filter-icon.svg', 'header/filter-icon')
            .subscribe();
        this.iconReg.loadSvg('assets/images/header/map-icon.svg', 'header/map-icon').subscribe();
        this.iconReg.loadSvg('assets/images/header/list-icon.svg', 'header/list-icon').subscribe();

        /**
         * Forms
         */
        this.iconReg.loadSvg('assets/images/forms/dog-icon.svg', 'forms/dog-icon').subscribe();
        this.iconReg.loadSvg('assets/images/forms/cat-icon.svg', 'forms/cat-icon').subscribe();
        this.iconReg.loadSvg('assets/images/forms/house-icon.svg', 'forms/house-icon').subscribe();
        this.iconReg.loadSvg('assets/images/forms/close-icon.svg', 'forms/close-icon').subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/apartment-icon.svg', 'forms/apartment-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/whatever-icon.svg', 'forms/whatever-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/fenced-garden-icon.svg', 'forms/fenced-garden-icon')
            .subscribe();
        this.iconReg
            .loadSvg(
                'assets/images/forms/non-fenced-garden-icon.svg',
                'forms/non-fenced-garden-icon',
            )
            .subscribe();
        this.iconReg
            .loadSvg(
                'assets/images/forms/smoking-accomodation-icon.svg',
                'forms/smoking-accomodation-icon',
            )
            .subscribe();
        this.iconReg
            .loadSvg(
                'assets/images/forms/non-smoking-accomodation-icon.svg',
                'forms/non-smoking-accomodation-icon',
            )
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/location-icon.svg', 'forms/location-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/calendar-icon.svg', 'forms/calendar-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/select-arrow.svg', 'forms/select-arrow')
            .subscribe();
        this.iconReg.loadSvg('assets/images/forms/email-icon.svg', 'forms/email-icon').subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/password-icon.svg', 'forms/password-icon')
            .subscribe();
        this.iconReg.loadSvg('assets/images/forms/phone-icon.svg', 'forms/phone-icon').subscribe();
        this.iconReg.loadSvg('assets/images/forms/user-icon.svg', 'forms/user-icon').subscribe();
        this.iconReg.loadSvg('assets/images/forms/time-icon.svg', 'forms/time-icon').subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/picture-icon.svg', 'forms/picture-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/profile-picture-icon.svg', 'forms/profile-picture-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/forms/remove-icon.svg', 'forms/remove-icon')
            .subscribe();
        this.iconReg.loadSvg('assets/images/forms/euro-icon.svg', 'forms/euro-icon').subscribe();
        this.iconReg.loadSvg('assets/images/forms/star.svg', 'forms/star').subscribe();
        this.iconReg.loadSvg('assets/images/forms/target.svg', 'forms/target').subscribe();

        /**
         * Profile
         */
        this.iconReg
            .loadSvg('assets/images/profile/info-icon.svg', 'profile/info-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/profile/about-icon.svg', 'profile/about-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/profile/reviews-icon.svg', 'profile/reviews-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/profile/euro2-icon.svg', 'profile/euro-icon')
            .subscribe();

        /**
         * Services
         */
        this.iconReg
            .loadSvg('assets/images/services/accommodation-icon.svg', 'services/accommodation-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/services/home-care-icon.svg', 'services/home-care-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/services/nursery-icon.svg', 'services/nursery-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/services/walk-icon.svg', 'services/walk-icon')
            .subscribe();

        /**
         * Bookings
         */
        this.iconReg
            .loadSvg('assets/images/bookings/contacts-icon.svg', 'bookings/contacts-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/bookings/waiting-icon.svg', 'bookings/waiting-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/bookings/valid-icon.svg', 'bookings/valid-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/bookings/finished-icon.svg', 'bookings/finished-icon')
            .subscribe();

        /**
         * Common
         */
        this.iconReg.loadSvg('assets/images/star.svg', 'star').subscribe();
        this.iconReg
            .loadSvg('assets/images/profile/star-outline-icon.svg', 'star-outline')
            .subscribe();
        this.iconReg.loadSvg('assets/images/paw-icon.svg', 'paw-icon').subscribe();
        this.iconReg.loadSvg('assets/images/award-icon.svg', 'award-icon').subscribe();
        this.iconReg.loadSvg('assets/images/right-arrow.svg', 'right-arrow-icon').subscribe();
        this.iconReg
            .loadSvg('assets/images/exclammation-circle-icon.svg', 'exclammation-circle-icon')
            .subscribe();
        this.iconReg
            .loadSvg('assets/images/send-message-icon.svg', 'send-message-icon')
            .subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
