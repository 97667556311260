import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/stores';
import { LocationState } from 'src/stores/location/location.state';

const selectLocation = createFeatureSelector<AppState, LocationState>('location');

const selectStatus = createSelector(selectLocation, ({ status }) => status);
const selectCoords = createSelector(selectLocation, ({ coords }) => coords);

export const LocationSelectors = {
    selectStatus,
    selectCoords,
};
