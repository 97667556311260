import { BookingStatus } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { BookingTab } from 'src/app/features/user/guards/booking-tab.guard';
import { BookingsPage } from 'src/app/features/user/pages/bookings/bookings.page';
import { InformationsPage } from 'src/app/features/user/pages/bookings/informations/informations.page';
import { ClientType } from 'src/app/shared/guards/client-type.guard';

export const BOOKING_PAGES = [BookingsPage, InformationsPage];

const tabs: { tab: BookingTab; statuses: BookingStatus[] }[] = [
    { tab: 'CONTACTS', statuses: ['CONTACT_REQUEST', 'AWAITING_CLIENT_VALIDATION'] },
    { tab: 'WAITING', statuses: ['AWAITING_PET_SITTER_VALIDATION'] },
    { tab: 'VALID', statuses: ['APPROVED'] },
    { tab: 'FINISHED', statuses: ['FINISHED', 'CANCELED', 'RATED'] },
];

export const getStatusesByTabAndClientType = (
    _clientType: ClientType,
    tab: BookingTab,
): BookingStatus[] => {
    for (const tabItem of tabs) {
        if (tabItem.tab === tab) {
            return tabItem.statuses;
        }
    }

    return [];
};

export const getBookingTabByStatus = (status: BookingStatus): BookingTab => {
    for (const tab of tabs) {
        if (tab.statuses.includes(status)) {
            return tab.tab;
        }
    }

    return null;
};
