import { Component, Input } from '@angular/core';
import { convertToBoolProperty } from 'src/app/shared/components/helpers';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {
    @Input() title?: string;
    @Input()
    set noPadding(value: unknown) {
        this.hasPadding = !convertToBoolProperty(value);
    }

    hasPadding = true;
}
