import { Component, EventEmitter, Input, Output } from '@angular/core';
import dayjs from 'dayjs';
import { Month } from 'src/app/core/services/calendar.service';

@Component({
    selector: 'app-calendar-month',
    templateUrl: './calendar-month.component.html',
    styleUrls: ['./calendar-month.component.scss'],
})
export class CalendarMonthComponent {
    @Input() month: Month;
    @Input() color: 'lightPurple' | 'red';
    @Input() selectedColor: 'lightPurple' | 'yellow' | 'red' | 'green';
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() selectedDays: string[] = [];
    @Input() showCurrent: boolean;
    @Input() disableNonAvailableDates = false;
    @Input() availabilities: string[];

    @Output() onSelect = new EventEmitter<string>();

    today = dayjs().format('YYYY-MM-DD');

    isCurrent(day: string): boolean {
        return day === this.today;
    }

    isDisabled(day: string): boolean {
        return day < this.today;
    }

    isNotAvailable(day: string): boolean {
        return this.disableNonAvailableDates && this.availabilities.indexOf(day) === -1;
    }

    isSelected(day: string): boolean {
        if (!this.selectedDays) {
            return null;
        }

        for (const selected of this.selectedDays) {
            if (selected === day) {
                return true;
            }
        }

        if (!this.startDate) {
            return false;
        }
        if (!this.endDate) {
            return this.startDate === day;
        }

        return day >= this.startDate && day <= this.endDate;
    }

    getDayOfMonth(day: string): string {
        return dayjs(day, 'YYYY-MM-DD').format('D');
    }
}
