import { PipeTransform, Pipe } from '@angular/core';
import { PetSitterResponseDto } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';

@Pipe({ name: 'displayName' })
export class DisplayNamePipe implements PipeTransform {
    transform(petSitter: PetSitterResponseDto): string {
        if (!petSitter) {
            return null;
        }

        const { firstName, lastName } = petSitter;

        return `${firstName} ${lastName.toUpperCase().slice(0, 1)}.`;
    }
}
