import {
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AddressModalComponent } from 'src/app/shared/components/forms/address/address-modal/address-modal.component';
import { AddressDto } from 'src/app/shared/dtos/address.dto';

@Component({
    selector: 'app-forms-address',
    templateUrl: './address.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressComponent),
            multi: true,
        },
    ],
})
export class AddressComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() allowCurrentLocation = false;
    @Input() formControlName: string;

    control: FormControl;

    selected: AddressDto;

    onDestroy$ = new Subject<void>();

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: AddressDto): void {
        if (value) {
            this.selected = value;
            this.control?.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    async openModal(): Promise<void> {
        const instance = await this.modalService.openIonModal({
            component: AddressModalComponent,
            componentProps: { allowCurrentLocation: this.allowCurrentLocation },
        });
        const { data } = await instance.onWillDismiss<AddressDto>();
        if (data) {
            this.selected = data;
            this.propagateChange(data);
        }
        this.control.markAsTouched();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
