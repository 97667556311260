import {
    Component,
    forwardRef,
    Host,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-forms-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formControlName: string;
    @Input() text: string;

    checked = false;

    control: FormControl;

    onDestroy$ = new Subject<void>();

    propagateChange: (_: boolean) => void;
    propagateTouch: () => void;

    constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        if (this.controlContainer) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        }
    }

    registerOnChange(fn: (_: boolean) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: boolean): void {
        if (value) {
            this.checked = value;
            this.control?.markAsTouched();
        }
    }

    toggle(): void {
        this.checked = !this.checked;
        this.propagateChange(this.checked);
        this.propagateTouch();
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.control?.disable() : this.control?.enable();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
