import { EnvironmentInterface } from 'src/environments/environment.interface';

export const environment: EnvironmentInterface = {
    production: false,
    prefillLoginWithHasaname: false,
    iosVersion: '0.0.3',
    androidVersion: '0.0.3',
    api: {
        baseUrl: 'https://staging.api.dogvacances.fr/app',
        sseUrl: 'https://staging.api.dogvacances.fr/sse',
    },
    googleMapsApiKey: 'AIzaSyBH6xpfyYhm9uMEiwylwMS4KoRQAjTxr88',
    firebaseConfig: {
        apiKey: 'AIzaSyBon1QHOhTPd2qVDnZ-5t9ay0CK1qXF1Nw',
        authDomain: 'dogvacances-staging.firebaseapp.com',
        projectId: 'dogvacances-staging',
        storageBucket: 'dogvacances-staging.appspot.com',
        messagingSenderId: '611791929054',
        appId: '1:611791929054:web:b0546fe79bc345a37a0c4f',
    },
};
