import { PipeTransform, Pipe } from '@angular/core';
import dayjs from 'dayjs';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { BookingReviewResponseDto } from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';

@Pipe({ name: 'chatMessageTime' })
export class ChatMessageTimePipe implements PipeTransform {
    transform(date: Date): string {
        return dayjs(date).format('HH:mm');
    }
}
