import { BookingStatus } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import { MessagingTab } from 'src/app/features/user/guards/messaging-tab.guard';
import { ChatPage } from 'src/app/features/user/pages/messaging/chat/chat.page';
import { MessagingPage } from 'src/app/features/user/pages/messaging/messaging.page';
import { ClientType } from 'src/app/shared/guards/client-type.guard';

export const MESSAGING_PAGES = [MessagingPage, ChatPage];

const tabs: { tab: MessagingTab; statuses: BookingStatus[] }[] = [
    { tab: 'WAITING', statuses: ['CONTACT_REQUEST'] },
    {
        tab: 'IN_PROGRESS',
        statuses: ['AWAITING_CLIENT_VALIDATION', 'AWAITING_PET_SITTER_VALIDATION', 'APPROVED'],
    },
];

export const getMessagingStatusesByTabAndClientType = (
    _clientType: ClientType,
    tab: MessagingTab,
): BookingStatus[] => {
    for (const tabItem of tabs) {
        if (tabItem.tab === tab) {
            return tabItem.statuses;
        }
    }

    return [];
};

export const getMessagingTabByStatus = (status: BookingStatus): MessagingTab => {
    for (const tab of tabs) {
        if (tab.statuses.includes(status)) {
            return tab.tab;
        }
    }

    return null;
};
