import { createAction, props } from '@ngrx/store';
import { BookingResponseDto } from 'src/app/core/services/dog-vacances-api/bookings/bookings-responses.dtos';
import {
    PetSitterResponseDto,
    SearchPetSitterDto,
} from 'src/app/core/services/dog-vacances-api/pet-sitting/pet-sitting.dtos';
import { PetResponseDto, PetType } from 'src/app/core/services/dog-vacances-api/pets/pets.dtos';
import { ContactRequestFormDto } from 'src/app/features/pet-sitting/forms/contact-request/contact-request-form.dto';

const domain = '[PET-SITTING]';

const clear = createAction(`${domain} Clear`);

/**
 * Search
 */
const updateSearchParams = createAction(
    `${domain} Update search params`,
    props<{ dto: SearchPetSitterDto }>(),
);
const search = createAction(`${domain} Search`);
const searchSuccess = createAction(
    `${domain} Search success`,
    props<{
        results: PetSitterResponseDto[];
        totalPages: number;
        minPrice: number;
        maxPrice: number;
    }>(),
);
const SEARCH_ACTIONS = { updateSearchParams, search, searchSuccess };

/**
 * Load more
 */
const loadMore = createAction(`${domain} Load more`);
const loadMoreSuccess = createAction(
    `${domain} Load more success`,
    props<{ results: PetSitterResponseDto[]; totalPages: number }>(),
);
const LOAD_MORE_ACTIONS = { loadMore, loadMoreSuccess };

/**
 * Contact request
 */
const contactRequestDomain = `${domain}[CONTACT-REQUEST]`;
const setPetSitterId = createAction(
    `${domain}[Contact request] Set petSitterId`,
    props<{ petSitterId: string }>(),
);
const setPetSitterPetPreference = createAction(
    `${domain}[Contact request] Set petSitterPetPreference`,
    props<{ petSitterPetPreference: PetType }>(),
);
const setPet = createAction(`${contactRequestDomain} Set pet`, props<{ pet: PetResponseDto }>());
const setForm = createAction(
    `${contactRequestDomain} Set form`,
    props<{ form: ContactRequestFormDto }>(),
);
const clearContactRequest = createAction(`${contactRequestDomain} Clear contact request`);
const create = createAction(
    `${contactRequestDomain} Create`,
    props<{ petSitterId: string; dto: ContactRequestFormDto }>(),
);
const createSuccess = createAction(
    `${contactRequestDomain}[Contact request] Create success`,
    props<{ booking: BookingResponseDto }>(),
);
const createError = createAction(
    `${contactRequestDomain}[Contact request] Create error`,
    props<{ error: string }>(),
);
const CONTACT_REQUEST_ACTIONS = {
    setPet,
    setForm,
    setPetSitterId,
    setPetSitterPetPreference,
    clear: clearContactRequest,
    create,
    createSuccess,
    createError,
};

export const PetSittingActions = {
    clear,
    ...SEARCH_ACTIONS,
    ...LOAD_MORE_ACTIONS,
    contactRequest: {
        ...CONTACT_REQUEST_ACTIONS,
    },
};
