import { Component, Input, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss'],
})
export class GalleryModalComponent {
    @Input() images: string[];
    @Input() activeIndex: number;

    @ViewChild(IonSlides) slider: IonSlides;

    constructor(private modalService: ModalService) {}

    ionViewWillEnter(): void {
        void this.slider.update();
    }

    close(): void {
        this.modalService.dismissIonModal();
    }
}
